export default {
  getIsPageLoading: (state) => (resource) => {
    return state.resources[resource].isPageLoading
  },
  getIsActionLoading: (state) => (resource) => {
    return state.resources[resource].isActionLoading
  },
  getError: (state) => (resource) => {
    return state.resources[resource].error
  },
  getRecord: (state) => ({ resource, key, value }) => {
    return state.resources[resource].data.find(record => record[key] === value)
  },
  getRecordList: (state) => (resource) => {
    return state.resources[resource].data
  },
  getPagedRecordList: (state) => (resource) => {
    return { data: state.resources[resource].data, filteredTotal: state.resources[resource].filteredTotal, pageInfo: state.resources[resource].pageInfo }
  },
  getPermission: (state) => (permission) => {
    return state.resources.permissions[permission]
  },
  getLatest: (state) => (resource) => {
    const recordList = state.resources[resource].data
    return recordList.length === 0 ? {} : recordList[0]
  },
  getFilteredRecords: (state) => (resource, resourceField, value) => {
    return state.resources[resource].data.filter(record => record[resourceField] === value)
  },
  getFilteredErrors: (state) => (resource, resourceField, value) => {
    if (!state.resources[resource].error) {
      return []
    }

    return state.resources[resource].error.filter(record => record[resourceField] === value)
  },
  getState: (state) => (resource) => {
    return state.resources[resource].state
  },
  getItem: (state) => (resource) => {
    return state.resources[resource].item
  },
  getCancelState: (state) => (resource) => {
    return state.resources[resource].cancelState
  }
}
