export default {
  parseDateCondition (condition) {
    const date = condition.date ? new Date(condition.date) : new Date()
    const years = condition.years || 0
    const months = condition.months || 0
    const days = condition.days || 0

    date.setFullYear(date.getFullYear() + years)
    date.setMonth(date.getMonth() + months)
    date.setDate(date.getDate() + days)

    return date
  },
  getSections: (formPages, hasCoborrower) => {
    const section = []
    formPages.forEach((page, index) => {
      if (section.length === 0 || section.slice(-1)[0].name !== page.step) {
        section.push({ name: page.step, pages: [] })
      }
      if (hasCoborrower) {
        page.pageIndex = index
        if (!page.hideInStepper || !page.nestedParentPage) {
          section.slice(-1)[0].pages.push(page)
        }
      } else if (!page.key.startsWith('co_borrower') || page.key === 'co_borrower-coborrower_info__overview') {
        page.pageIndex = index
        if (!page.hideInStepper || !page.nestedParentPage) {
          section.slice(-1)[0].pages.push(page)
        }
      }
    })
    return section
  },
  fuzzyMatch: (fieldType, newValue, storeValue) => {
    const numbersRegex = /\D+/g
    let fieldsEqual = false

    switch (fieldType) {
      case 'date':
      case 'ssn':
      case 'phone':
        fieldsEqual = (newValue.replace(numbersRegex, '') === storeValue?.replace(numbersRegex, ''))
        break
      case 'boolean':
        // Checking for the case when a string of '0' or '1' was stored rather than false/true
        fieldsEqual = (newValue === '0' && storeValue === false) || (newValue === '1' && storeValue === true)
        break
      default:
        fieldsEqual = false // we already know there is a difference so return false
    }

    return fieldsEqual
  },
  getPossibleFieldMatches: (searchTerm, fields, formValues) => {
    if (!searchTerm) return []
    const possibleKeys = []
    fields.forEach(field => {
      const formattedField = getFormattedField(field, searchTerm)
      if (formattedField) {
        possibleKeys.push(formattedField)
      }
      possibleKeys.push(...getFormattedDynamicAddFields(field, searchTerm, formValues))
    })
    return possibleKeys
  },
  getSectionMatchesFromKeys: (fields, structure) => {
    if (fields.length < 1) return []
    const sectionMatches = {}
    structure.forEach(page => {
      const intersection = fields.filter(x => page.fields.includes(x.key) || page.fields.includes(x.parentKey))
      if (!intersection.length) return []
      const intersectFields = intersection.map(field => {
        return { ...field, pageIndex: page.pageIndex }
      })
      if (sectionMatches[page.step]) {
        sectionMatches[page.step].push(...intersectFields)
      } else {
        sectionMatches[page.step] = intersectFields
      }
    })
    return sectionMatches
  }
}

// Private helper functions
function getFormattedField (field, searchTerm) {
  if (field.title && field.title.toLowerCase().includes(searchTerm.toLowerCase())) {
    const formattedField = {
      ...field,
      title: formatTitleForSearch(field.title, searchTerm)
    }
    return formattedField
  } else if (field.text && field.text.toLowerCase().includes(searchTerm.toLowerCase())) {
    const formattedField = {
      ...field,
      title: formatTitleForSearch(field.text, searchTerm)
    }
    return formattedField
  }
  return null
}

function getFormattedDynamicAddFields (field, searchTerm, formValues) {
  if (!field.template?.fields) {
    return []
  }

  const formattedFields = []
  field.template.fields.forEach(dynamicField => {
    const formattedField = getFormattedField(dynamicField, searchTerm)
    const visibleDynamicField = getVisibleDynamicField(dynamicField.key, formValues)
    if (formattedField && visibleDynamicField) {
      formattedFields.push({ ...formattedField, key: visibleDynamicField, parentKey: field.key })
    }
  })
  return formattedFields
}

function getVisibleDynamicField (key, formValues) {
  return Object.keys(formValues).find(val => {
    return val.replaceAll(/\d+/g, '') === key.replaceAll('INDEX', '')
  })
}

function formatTitleForSearch (title, searchTerm) {
  const strippedTitle = title.replace(/(<([^>]+)>)/ig, '') // strip html tags
  const words = strippedTitle.split(/\s+/g)
  const splitSearchTerm = searchTerm.split(/\s+/g)
  if (splitSearchTerm.length < 1) {
    return strippedTitle
  }
  const firstSearchedWord = splitSearchTerm[0]

  if (words.length > 4) {
    const foundIndex = words.findIndex((word) => word.toLowerCase().includes(firstSearchedWord.toLowerCase()))
    // Slice forward or back 3 words if possible
    const startIndex = Math.max(0, foundIndex - 3)
    const endIndex = Math.min(foundIndex + (splitSearchTerm.length + 3), words.length)
    let slicedWords = words.slice(startIndex, endIndex).join(' ')
    if (startIndex > 0) {
      slicedWords = '...' + slicedWords
    }
    if (endIndex < words.length - 1) {
      slicedWords += '...'
    }
    return slicedWords
  }
  return strippedTitle
}
