import Vue from 'vue'
import Vuex from 'vuex'
import getters from './LoanOfficerGetters'
import actions from './LoanOfficerActions'
import mutations from './LoanOfficerMutations'
import LoanViewStore from '~/store/loanOfficer/loanViewStore'
import Lo1003Store from '~/components/loanOfficerApp/lo1003/store/Lo1003Store'
import DashboardStore from '~/components/loanOfficerApp/dashboard/store/DashboardStore'
import ChatStore from '~/components/chat/v2/store'
import PricingStore from '~/components/loanOfficerApp/pricing/store/pricingStore'
import DocumentsStore from '~/components/loanOfficerApp/loanDetails/documents/store/DocumentsStore'
import ShareFlowStore from '~/components/loanOfficerApp/shareFlow/ShareFlowStore'
import TaskStore from '~/components/loanOfficerApp/loanDetails/tasks/store/TaskStore'

Vue.use(Vuex)

if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
  Vue.config.devtools = true
}

export const store = new Vuex.Store({
  modules: {
    loanView: LoanViewStore,
    Lo1003: Lo1003Store,
    Chat: ChatStore,
    Documents: DocumentsStore,
    Dashboard: DashboardStore,
    ShareFlow: ShareFlowStore,
    Pricing: PricingStore,
    Task: TaskStore
  },
  state: () => ({
    borrower: null,
    coBorrower: null,
    currentUser: {},
    docStorageLocation: '',
    isRemoteBorrower: false,
    loadingLoanApplication: false,
    loadingStoreSetup: false,
    loanOwner: null,
    loanOwnerName: '',
    losName: '',
    permissions: null,
    selectedLoan: null,
    selectedLoanApp: null,
    servicerActivationCode: '',
    servicerProfile: null,
    supportPhone: '',
    teamMemberGuid: '',
    teamMemberId: '',
    _toasts: [],
    refreshLoanDocs: false,
    loadingLoan: false,
    loadingTasks: false
  }),
  getters,
  mutations,
  actions
})
