import { productDetailsQuery } from '../gql/pricingProductQueries.gql'
import apolloClient from '~/util/apolloClient'
const { provider } = apolloClient()

export default {
  getProductDetails (context, { channelIndex, product, loanAmount }) {
    context.state.productDetailsLoading = true
    context.state.productDetailsError = false
    provider.clients.defaultClient.query({
      fetchPolicy: 'network-only',
      query: productDetailsQuery,
      variables: {
        channelIndex: channelIndex,
        searchId: product.searchId,
        productId: product.productId,
        loanTerm: product.loanTerm,
        loanAmount: loanAmount
      }
    }).then(({ data: { productDetails } }) => {
      context.state.productDetails = { ...product, ...productDetails }
    }).catch(() => {
      context.state.productDetailsError = true
    }).finally(() => {
      context.state.productDetailsLoading = false
    })
  }
}
