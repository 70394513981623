import AppCommunicationMixin from '~/components/common/mixins/AppCommunicationMixin'
import apolloClient from '~/util/apolloClient'
import helperMethods from './Lo1003HelperMethods.js'
import { mobileEventsEnums, serviceEnums } from '~/components/loanOfficerApp/lo1003/utilities/enums'
import { saveUserLoanAppValues, submitUserLoanApp } from '~/components/loanOfficerApp/lo1003/graphQL/mutations/lo1003UserMutations.gql'
import { updateUserLoanAppNote, createUserLoanAppNote, deleteUserLoanAppNote } from '~/components/loanOfficerApp/lo1003/graphQL/mutations/lo1003NoteMutations.gql'
import { isEqual } from 'lodash'

const { provider } = apolloClient()

export default {
  setUpForm (context, {
    form
  }) {
    if (form) {
      form.structure.forEach((s) => {
        if (s.complete === undefined) {
          s.complete = false
        }
        s.missingRequiredFields = 0
      })
      form.fields.forEach((field) => {
        if (field.key.includes('dynamic_add')) {
          field.template.fields.forEach((f) => {
            form.fields.push({ ...f, parentKey: field.key })
          })
        }
      })
    }
    context.commit('setForm', form)
    context.dispatch('setCurrentPageIndex', context.getters.sections[0].pages[0].pageIndex)
    context.commit('setExpandedNavSection', form.structure[0].step)
  },
  setInitialData ({ commit }, {
    borrower,
    coBorrower
  }) {
    commit('setBorrowers', {
      borrower: borrower,
      coBorrower: coBorrower
    })
    commit('setCurrentBorrower', borrower)
  },
  setIsLoanAppLocked (context, locked) {
    context.state.isLoanAppLocked = locked
  },
  setCurrentPageIndex (context, pageIndex) {
    context.commit('setCurrentPageIndex', pageIndex)
    context.dispatch('postMessageToMobile', {
      event: mobileEventsEnums.PAGE_INDEXES_UPDATED,
      payload: {
        currentPageIndex: context.getters.getCurrentPageIndex,
        lastPageIndex: context.getters.getIndexOfLastPage
      }
    })
  },
  newStepperSection (context, { stepperItem }) {
    if (
      context.state.lo1003Form.structure.indexOf(context.state.currentPage) !== context.state.lo1003Form.structure.indexOf(stepperItem)
    ) {
      context.dispatch('setCurrentPageIndex', context.state.lo1003Form.structure.indexOf(stepperItem))
      context.commit('resetVisibleFields')
      context.commit('setExpandedNavSection', stepperItem.step)
    }
  },
  navigateAndFocusOnField (context, { index, step, fieldKey, isMobile }) {
    if (index !== context.getters.getCurrentPageIndex) {
      context.dispatch('setCurrentPageIndex', index)
      context.commit('resetVisibleFields')
      context.commit('setExpandedNavSection', step)
    }
    context.commit('setFocusedField', fieldKey)
    context.commit('setFieldToScrollTo', fieldKey)
    if (isMobile) context.commit('setMobileFocusedField', fieldKey)
  },
  changeBorrower (context, { borrowerType }) {
    const inBorrowerView = borrowerType === 'BORROWER'
    const section = inBorrowerView ? 0 : 1
    context.dispatch('newStepperSection', { stepperItem: context.getters.sections[section].pages[0] })
    context.dispatch('postMessageToMobile', { event: mobileEventsEnums.IN_BORROWER_VIEW_UPDATED, payload: { inBorrowerView } })
    context.dispatch('postMessageToMobile', { event: mobileEventsEnums.CURRENT_BORROWER_CHANGED, payload: { inBorrowerView } })
  },
  addCoborrower (context) {
    context.commit('setHasCoborrower', true)
    context.commit('updateValue', { key: 'has_coborrower', value: true })
    context.dispatch('newStepperSection', { stepperItem: context.getters.sections[1].pages[0] })
    context.dispatch('updateBorrowerInfoEvent')
    context.dispatch('changeBorrower', { borrowerType: context.getters.getCoBorrower.type })
  },
  deleteCoborrower (context) {
    context.commit('setHasCoborrower', false)
    context.commit('updateValue', { key: 'has_coborrower', value: false })
    Object.keys(context.state.lo1003Form.values).forEach((key) => {
      if (key.startsWith('coborrower') || key.startsWith('co_borrower')) {
        context.commit('removeFormValue', { key })
      }
    })
    context.dispatch('newStepperSection', { stepperItem: context.getters.sections[1].pages[0] })
    context.dispatch('updateBorrowerInfoEvent')
  },
  addInvalidField (context, { key }) {
    if (!context.getters.isInvalidField(key)) {
      context.commit('addToInvalidFields', key)
    }
  },
  nextSection (context) {
    const nextSectionIndex = context.getters.getNextPageIndex
    context.dispatch('setCurrentPageIndex', nextSectionIndex)
    context.commit('resetVisibleFields')
    context.commit('clearInvalidFields')
    context.commit('setExpandedNavSection', context.getters.getLo1003Form.structure[nextSectionIndex].step)
  },
  previousSection (context) {
    const previousSectionIndex = context.getters.goBackPageIndex
    context.dispatch('setCurrentPageIndex', previousSectionIndex)
    context.commit('resetVisibleFields')
    context.commit('clearInvalidFields')
    context.commit('setExpandedNavSection', context.getters.getLo1003Form.structure[previousSectionIndex].step)
  },
  removeError (context, { key }) {
    const index = context.getters.getInvalidFields.indexOf(key)
    if (index >= 0) context.commit('removeFromInvalidFields', index)
  },
  fieldVisible (context, { field }) {
    if (!context.state.visibleFields.includes(field)) {
      context.commit('addToVisibleFields', field)
    }
  },
  focusedFieldClear (context) {
    context.commit('setFocusedField', null)
  },
  focusedFieldSet (context, { key }) {
    context.commit('setFocusedField', key)
  },
  updateFieldValues (context, { fields }) {
    context.commit('setValues', fields)
  },
  handleSave (context, { key, value }) {
    if (key.includes('detailed_other_income')) {
      const index = parseInt(key.split('_')[3])
      if (typeof index === 'number') {
        context.dispatch('saveValue', { key: `detailed_other_income_${index}_applicant`, value: (value === '' ? '' : (index < 4 ? 'Borrower' : 'CoBorrower')) })
      }
    } else if (key === 'loan_purpose') {
      const streetVal = context.getters.getLo1003Form.values.property_street
      context.dispatch('saveValue', { key: 'property_street', value: (value === 'Refinance' || value === 'Cash-Out Refinance') ? (streetVal === 'TBD' ? '' : streetVal) : 'TBD' })

      if (value === 'Refinance' || value === 'Cash-Out Refinance') {
        context.commit('removeFormValue', { key: 'loan_amount' })
      }
    } else if (key === 'property_own') {
      if (value === 'Own') {
        context.dispatch('saveValue', { key: 'vom_property_owned_by_1', value: 'Borrower' })
        context.dispatch('saveValue', { key: 'own_real_estate', value: '1' })
      } else {
        context.commit('removeFormValue', { key: 'vom_property_owned_by_1' })
        context.commit('removeFormValue', { key: 'own_real_estate' })
      }
    }

    context.dispatch('saveValue', { key: key, value: value })
    // check for conditional values
    const field = context.getters.fieldFromKey(key)
    if (field?.dependents) {
      context.dispatch('handleConditional', {
        dependents: field.dependents
      })
    }
  },
  saveAndClose (context) {
    context.dispatch('saveNote')
    context.dispatch('saveValues')
    // set timeout so Success message has time to display
    setTimeout(() => {
      context.dispatch('redirectToLoanDetails')
    }, 1000)
  },
  async saveValues (context) {
    context.commit('setIsSaving', true)
    if (context.getters.getLo1003Form.values.property_street === 'TBD') {
      context.commit('removeFormValue', { key: 'property_street' })
    }
    try {
      const values = JSON.stringify(context.getters.getLo1003Form.values)
      const { data: { save_user_loan_app_values: { success } } } = await provider.clients.defaultClient.mutate({
        mutation: saveUserLoanAppValues,
        variables: {
          guid: context.getters.getLoanApp.guid,
          values: values
        }
      })
      if (success) {
        context.commit('setSaveError', { error: false, errorMessage: '' })
        context.commit('setIsSaving', false)
        context.commit('setValuesWereSaved', true)
        setTimeout(() => { context.dispatch('valuesHaveChanged', false) }, 1000)
        // send borrower update event to mobile
        context.dispatch('updateBorrowerInfoEvent')
        context.dispatch('formSavedResultEvent', true)
        if (context.getters.getLo1003Form.values.loan_purpose !== 'Refinance' && context.getters.getLo1003Form.values.loan_purpose !== 'Cash-Out Refinance') {
          context.dispatch('saveValue', { key: 'property_street', value: 'TBD' })
        }
      }
    } catch (error) {
      context.commit('setSaveError', { error: error, errorMessage: 'Oops! We couldn\'t save this loan application.' })
      context.dispatch('formSavedResultEvent', false)
      context.commit('setIsSaving', false)
    }
  },
  submit1003 (context) {
    const values = JSON.stringify(context.getters.getLo1003Form.values)
    return provider.clients.defaultClient.mutate({
      mutation: submitUserLoanApp,
      variables: {
        guid: context.getters.getLoanApp.guid,
        values: values
      }
    })
  },
  handleConditional (context, { dependents }) {
    dependents.forEach(dependent => {
      const dependentField = context.getters.fieldFromKey(dependent)
      const conditionalValue = context.getters.getConditionalValue(dependentField.computed?.condition)
      if (conditionalValue) {
        context.commit('addToVisibleFields', dependent)
      } else if (!conditionalValue) {
        context.commit('removeFromInvalidFields', dependent)
        context.commit('removeFromVisibleFields', dependent)
        context.dispatch('removeFormValues', { key: dependent })
      }
    })
  },
  removeFormValues (context, { key }) {
    if (key.includes('_dynamic_add')) {
      const dynamicAddField = context.getters.fieldFromKey(key)
      // get the indexed field for the dynamic add
      const fields = dynamicAddField.template.fields
      fields.forEach(field => {
        // get a list of all the indexed field keys in our values object for the dynamic add field
        const fieldsToRemove = context.getters.findAllDynamicAddFieldKeys(field.key)
        fieldsToRemove.forEach(keyToRemove => {
          context.commit('removeFormValue', { key: keyToRemove })
        })
      })
    } else {
      context.commit('removeFormValue', { key })
    }
  },
  fillBorrowerSelfEmployedField (context, { key, employmentType }) {
    if (context.state.lo1003Form.values.employment_status === 'I\'m currently employed') {
      if (employmentType === 'Self-employed') {
        if (key === 'borrower_voe_employment_category_1') {
          context.dispatch('saveValue', { key: 'borrower_voe_employment_is_self_employed_1', value: '1' })
        } else if (key === 'coborrower_voe_employment_is_self_employed_1') {
          context.dispatch('saveValue', { key: 'coborrower_voe_employment_is_self_employed_1', value: '1' })
        }
      } else if (key === 'borrower_voe_employment_category_1') {
        context.dispatch('saveValue', { key: 'borrower_voe_employment_is_self_employed_1', value: '0' })
      } else if (key === 'coborrower_voe_employment_is_self_employed_1') {
        context.dispatch('saveValue', { key: 'coborrower_voe_employment_is_self_employed_1', value: '0' })
      }
    }
  },
  updateSelectedService (context, service) {
    context.commit('setSelectedService', service)
    if (context.getters.getMissingRequiredFields === undefined || context.getters.getMissingRequiredFields?.length === 0) {
      if ([serviceEnums.AUS.type, serviceEnums.CREDIT.type, serviceEnums.PRE_APPROVAL.type, serviceEnums.PRE_QUAL.type].includes(service)) {
        if (context.getters.getIsLoanAppLocked) {
          if (service === serviceEnums.PRE_APPROVAL.type) {
            context.commit('setRedirectQueryParam', 'pre_approval')
          } else if (service === serviceEnums.PRE_QUAL.type) {
            context.commit('setRedirectQueryParam', 'prequal')
          } else if (service === serviceEnums.CREDIT.type) {
            context.commit('setRedirectQueryParam', 'services')
          } else if (service === serviceEnums.AUS.type) {
            context.commit('setIsOrderAusModalOpen', true)
            return
          }
          context.dispatch('redirectToLoanDetails')
        } else if (service === serviceEnums.AUS.type && !this.valuesHaveChanged) {
          context.commit('setIsOrderAusModalOpen', true)
        } else {
          context.commit('setShowRunServiceModal', true)
        }
      }
    }
  },
  valuesHaveChanged (context, value) {
    // Only send a valuesHaveChanged event if the state changes
    if (context.state.valuesHaveChanged !== value) {
      context.commit('setValuesHaveChanged', value)
      context.dispatch('postMessageToMobile', { event: mobileEventsEnums.VALUES_HAVE_CHANGED, payload: { valuesHaveChanged: value } })
    }
  },
  updateBorrowerInfoEvent (context) {
    // send name/phone/email to mobile
    const loanApp = context.getters.getLoanApp
    const payload = {
      borrower: context.getters.getBorrower,
      coBorrower: context.getters.getHasCoborrower ? context.getters.getCoBorrower : null,
      submittedDate: loanApp.submitted_at
    }
    context.dispatch('postMessageToMobile', {
      event: mobileEventsEnums.BORROWER_INFORMATION_UPDATED, payload: payload
    })
  },
  formSavedResultEvent (context, success) {
    const message = success ? '' : 'Oops! We couldn\'t save this loan application.'
    const result = { success: success, message: message }
    context.dispatch('postMessageToMobile', { event: mobileEventsEnums.FORM_SAVED_RESULT, payload: result })
  },
  saveValue (context, { key, value }) {
    // don't save if it's a dynamic add field from the form structure
    if (!key.includes('_dynamic_add')) {
      // if we're trying to save an empty string and we already have the field in our values, we should delete it
      // otherwise, we don't need to save it
      if (value === '' || value === undefined) {
        if (key in context.state.lo1003Form.values) {
          const newVals = {
            ...context.state.lo1003Form.values
          }
          delete newVals[key]
          context.commit('setValues', newVals)
          context.dispatch('valuesHaveChanged', true)
        }
      } else {
        // only update valuesHaveChanged if we're saving a different value from what we already have stored
        // or if its not already in the values
        if ((key in context.state.lo1003Form.values && !isEqual(value, context.state.lo1003Form.values[key])) || !(key in context.state.lo1003Form.values)) {
          if (!helperMethods.fuzzyMatch(context.getters.fieldFromKey(key)?.type, value, context.getters.getLo1003Form.values[key])) {
            context.dispatch('valuesHaveChanged', true)
          }
          context.commit('setValuesWereSaved', false)
        }
        context.commit('setValues', {
          ...context.state.lo1003Form.values,
          [key]: value
        })
      }
    }
  },
  postMessageToMobile (context, { event, payload }) {
    AppCommunicationMixin.methods.postMessageToApp(event, payload)
  },
  saveNote (context) {
    const value = context.getters.getUnsavedNoteValue

    if (value === undefined || value === '') {
      return
    }
    const loanAppNote = context.getters.getLoanAppNote
    let updating = false
    if (loanAppNote !== null && loanAppNote !== '' && loanAppNote !== undefined) {
      updating = true
    }
    if (updating) {
      context.dispatch('updateNote', value)
    } else {
      context.dispatch('createNote', value)
    }
  },
  updateNote (context, value) {
    provider.clients.defaultClient.mutate({
      mutation: updateUserLoanAppNote,
      variables: {
        guid: context.getters.getLoanApp.guid,
        note: value
      }
    }).then(({ data: { update_user_loan_app_note: { user_loan_app_note } } }) => {
      context.commit('setNoteValue', user_loan_app_note)
    }).catch((error) => {
      context.commit('setSaveError', { error: error, errorMessage: 'Oops! We couldn\'t save this note.' })
    })
  },
  createNote (context, value) {
    const loanApp = context.getters.getLoanApp

    provider.clients.defaultClient.mutate({
      mutation: createUserLoanAppNote,
      variables: {
        guid: loanApp.guid,
        note: value
      }
    }).then(({ data: { create_user_loan_app_note: { user_loan_app_note } } }) => {
      context.commit('setNoteValue', user_loan_app_note)
    }).catch((error) => {
      context.commit('setSaveError', { error: error, errorMessage: 'Oops! We couldn\'t save this note.' })
    })
  },
  deleteNote (context) {
    provider.clients.defaultClient.mutate({
      mutation: deleteUserLoanAppNote,
      variables: {
        guid: context.getters.getLoanApp.guid
      }
    }).then(() => {
      context.commit('setNoteValue', null)
    }).catch((error) => {
      context.commit('setSaveError', { error: error, errorMessage: 'Oops! We couldn\'t delete this note.' })
    })
  },
  async redirectToLoanDetails (context) {
    const loanApp = context.getters.getLoanApp
    const loanGuid = loanApp.loan_guid
    const queryParam = context.state.redirectQueryParam
    const referrer = document.referrer

    let url = `/servicer_loans/${loanApp.servicer_profile.id}/loan_details`
    if (queryParam === 'services' || referrer.includes('services')) {
      url += '/services'
    } else if (referrer.includes('tasks')) {
      url += '/tasks'
    } else if (referrer.includes('documents')) {
      url += '/documents'
    }

    if (loanGuid) {
      url += `?loanGuid=${loanGuid}`
    } else {
      url += `?loanAppGuid=${loanApp.guid}`
      if (queryParam === 'submitted') {
        url += '&loanAppSubmitted=true'
      }
    }

    if (queryParam === 'prequal') {
      url += '&generateLetter=prequal'
    } else if (queryParam === 'pre_approval') {
      url += '&generateLetter=pre_approval'
    }
    window.location.replace(url)
  },
  searchForm (context, searchTerm) {
    if (!searchTerm) {
      context.commit('setCurrentSearchResults', [])
    }
    const possibleFields = helperMethods.getPossibleFieldMatches(searchTerm, context.getters.getLo1003Form.fields, context.getters.getFormValues)
    const sections = helperMethods.getSectionMatchesFromKeys(possibleFields, context.getters.getLo1003Form.structure)
    // Only show fields that would be visible on the page
    Object.keys(sections).forEach((sectionKey) => {
      sections[sectionKey] = sections[sectionKey].filter(field => context.getters.getConditionalValue(field.computed?.condition))
      if (!sections[sectionKey].length || (sectionKey.includes('Co-borrower') && !context.getters.getHasCoborrower)) {
        delete sections[sectionKey]
      }
    })
    context.commit('setCurrentSearchResults', sections)
  },
  validateSection (context) {
    if (!context.getters.getIsLoanAppLocked) {
      for (const f of context.getters.getCurrentPage.fields) {
        if (this.$refs[f]) {
          const index = this.$refs[f].findIndex((el) => {
            return el._isVue
          })
          if (this.$refs[f][index] && typeof this.$refs[f][index].validate === 'function') {
            this.$refs[f][index].validate()
          }
        }
      }
    }

    if (!context.getters.getIsCurrentSectionValid) {
      this.$snackbard.show({ text: 'There\'s some invalid information. Please update before continuing.' })
      context.commit('setShouldScrollToError', true)
    } else {
      const navAction = context.getters.getNavAction
      switch (navAction.action) {
        case 'nextSection':
          context.dispatch('nextSection')
          break
        case 'previousSection':
          context.dispatch('previousSection')
          break
        case 'newStepperSection':
          context.dispatch('newStepperSection', { stepperItem: navAction.step })
          break
        case 'saveAndClose':
          context.dispatch('saveAndClose')
          break
      }
    }

    this.setValidateSection(false)
  }
}
