
export default {
  setPricingForm (state, payload) {
    state.form = payload
  },
  setChannelIndex (state, payload) {
    state.channelIndex = payload
  },
  setProductDetails (state, payload) {
    state.productDetails = payload
  },
  setProductDetailsLoading (state, payload) {
    state.productDetailsLoading = payload
  },
  setQuote (state, payload) {
    state.quote = payload
  },
  setSearchCriteriaGuid (state, payload) {
    state.searchCriteriaGuid = payload
  },
  setPricingProductsIsLoading (state, payload) {
    state.pricingProductsIsLoading = payload
  },
  setIneligiblePricingProductsIsLoading (state, payload) {
    state.ineligiblePricingProductsIsLoading = payload
  },
  setSearchCriteriaIsLoading (state, payload) {
    state.searchCriteriaIsLoading = payload
  },
  setSearchId (state, payload) {
    state.searchId = payload
  },
  setProductDetailsError (state, payload) {
    state.productDetailsError = payload
  }
}
