export const formatAddress = (loanProperty) => {
  let address = ''
  if (loanProperty && (loanProperty.city || loanProperty.state)) {
    address += loanProperty.city || ''
    if (loanProperty.state) {
      if (loanProperty.city) address += ', '
      address += loanProperty.state
    }
  }
  return address
}
