export default {
  getPricingForm: state => {
    return state.form
  },
  getPricingOptionsHeaders: (state, getters, rootState, rootGetters) => {
    const headers = rootGetters.getPermissions?.filterableFields
    if (!headers) return []

    const validJsonString = headers.replace(/"=>"/g, '":"')
    return JSON.parse(validJsonString)
  },
  getProductDetails: state => {
    return state.productDetails
  },
  getProductDetailsLoading: state => {
    return state.productDetailsLoading
  },
  getQuote: state => {
    return state.quote
  },
  getSearchCriteriaGuid: state => {
    return state.searchCriteriaGuid
  },
  isPricingProductsLoading: state => {
    return state.pricingProductsIsLoading
  },
  isIneligiblePricingRunning: state => {
    return state.ineligiblePricingProductsIsLoading
  },
  isSearchCriteriaRunning: state => {
    return state.searchCriteriaIsLoading
  },
  isProductListPageLoading: state => {
    return state.ineligiblePricingProductsIsLoading || state.pricingProductsIsLoading || state.searchCriteriaIsLoading
  },
  getSearchId: state => {
    return state.searchId
  },
  getChannelIndex: state => {
    return state.channelIndex
  },
  getProductDetailsError: state => {
    return state.productDetailsError
  }
}
