import _ from 'lodash'

// useful when a long stack trace / system message gets surfaced accidentally
// prevents toasts from running off the screen and being impossible to close (see INV-2943)
function truncate (str, truncateLength = 600, substrLength = 600) {
  if (str.length > truncateLength) {
    return str.substring(0, substrLength) + '...'
  }
  return str
}

export default {
  setToast (state, newToast) {
    const newToastId = _.uniqueId('toast_')
    state._toasts.push({ id: newToastId, ...newToast })
    if (newToast.event === 'success') {
      setTimeout(_ => {
        const index = state._toasts.findIndex(toast => toast.id === newToastId)
        state._toasts[index].active = false
      }, 7000)
    }
  },
  removeToast (state, toastId) {
    state._toasts = state._toasts.filter(toast => toast.id !== toastId)
  },
  setSuccessToast (state, { message = 'Success', description = '', type = 'simple' }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'success',
        active: true,
        description: truncate(description),
        type
      }
    ]
    setTimeout(_ => {
      state._toasts.find(toast => toast.id === newToastId).active = false
    }, 7000)
  },
  setSuccessActionToast (state, { message = 'Success', description = '', type = 'action', actionText = '', actionData = {} }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'success',
        active: true,
        description: truncate(description),
        type,
        actionText,
        actionData
      }
    ]
    setTimeout(_ => {
      state._toasts.find(toast => toast.id === newToastId).active = false
    }, 7000)
  },
  setSuccessStickyActionToast (state, { message = 'Success', description = '', type = 'stickyAction', actionText = '', actionData = {} }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'success',
        active: true,
        description: truncate(description),
        type,
        actionText,
        actionData
      }
    ]
  },
  setInfoToast (state, { message = 'Information', description = '', type = 'simple' }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'info',
        active: true,
        description: truncate(description),
        type
      }
    ]
    setTimeout(_ => {
      state._toasts.find(toast => toast.id === newToastId).active = false
    }, 7000)
  },
  setErrorToast (state, { message = 'Error', description = '', type = 'sticky' }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'error',
        active: true,
        description: truncate(description),
        type
      }
    ]
  },
  setWarningToast (state, { message = 'Warning', description = '', type = 'sticky' }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'warning',
        active: true,
        description: truncate(description),
        type
      }
    ]
  },
  setErrorStickyActionToast (state, { message = 'Error', description = '', type = 'stickyAction', actionText = '', actionData = {} }) {
    const newToastId = _.uniqueId('toast_')
    state._toasts = [...state._toasts,
      {
        id: newToastId,
        message,
        event: 'error',
        active: true,
        description: truncate(description),
        type,
        actionText,
        actionData
      }
    ]
  },
  setErrorToastType (state, { message = 'Error', description = '', type = '', actionText = '', actionData = {} }) {
    let truncatedDescription = null
    if (description.length > 250) {
      truncatedDescription = truncate(description, 250, 125) // 250 and 150 are arbitrary but look good when the error text is an xml response
      actionText = 'View details'
      actionData = {
        dataSource: 'errorOverflow',
        title: message,
        data: description
      }
      this.setErrorStickyActionToast(state, { description: truncatedDescription, actionText, actionData })
    } else {
      this.setErrorToast(state, { description })
    }
  }
}
