export default {
  setMilestones (state, payload) {
    state.milestones = payload
  },
  setMilestoneFilteredLoanTotals (state, payload) {
    state.milestoneFilteredLoanTotals = payload
  },
  setLoanAppTotal (state, payload) {
    state.loanAppTotal = payload
  },
  setServicerProfileId (state, payload) {
    state.servicerProfileId = payload
  },
  setServicer (state, payload) {
    state.servicerProfile = payload
  },
  setUser (state, payload) {
    state.user = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setShareAppAnalytics (state, payload) {
    state.shareAppAnalytics = payload
  },
  setNewProspectsAnalytics (state, payload) {
    state.newProspectsAnalytics = payload
  },
  setNewLoanAppAnalytics (state, payload) {
    state.newLoanAppAnalytics = payload
  },
  setUpcomingClosingsCount (state, payload) {
    state.upcomingClosingsCount = payload
  },
  setAbandonedLoanAppAnalytics (state, payload) {
    state.abandonedLoanAppAnalytics = payload
  },
  setLoanTotal (state, payload) {
    state.loanTotal = payload
  },
  setTasks (state, payload) {
    state.tasks = payload
  },
  setTotalTaskCount (state, payload) {
    state.totalTaskCount = payload
  },
  setCurrentTask (state, payload) {
    state.currentTask = payload
  },
  setCurrentSidePanel (state, payload) {
    state.currentSidePanel = payload
  },
  setShowSidePanel (state, payload) {
    state.showSidePanel = payload
  },
  setRecentActivityLogs (state, payload) {
    state.recentActivityLogs = payload
  },
  setActivityFeedSettings (state, payload) {
    state.activityFeedSettings = payload
  },
  setIsAssignedLO (state, isAssignedLO) {
    state.isAssignedLO = isAssignedLO
  },
  setSelectedLo (state, loanOfficer) {
    state.selectedLo = loanOfficer
  }
}
