<template>
  <sn-modal
    :value="value"
    type="decision"
    title="Download a document?"
    primary-text="Download"
    @input="$emit('input', $event)"
    @submit="validateDownloadFormat"
  >
    <v-form
      ref="downloadForm"
      v-model="downloadFormValid"
    >
      <div class="sn-body-1 mb-2">
        Choose which format to download
      </div>
      <sn-select
        v-model="downloadFormat"
        :rules="[input => !!input || 'Please select one of the options to continue']"
        :items="formats"
        required
        validate-on-blur
        placeholder="Select a file format"
      />
    </v-form>
  </sn-modal>
</template>

<script>

export default {
  name: 'DownloadFormatsModal',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formats: ['.fnm', '.fnma', '.txt', '.csv', '.pdf', 'DU MISMO 3.4'],
      downloadFormat: '',
      downloadFormValid: false
    }
  },
  methods: {
    async validateDownloadFormat () {
      await this.$refs.downloadForm.validate()
      if (!this.downloadFormValid) return
      this.$emit('download', this.downloadFormat)
    }
  }
}
</script>
