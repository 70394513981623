<template>
  <sn-side-panel
    :value="value"
    title="Sign disclosure"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="!!loadingDisclosure"
    @input="$emit('input', $event)"
    @primary-btn-click="handlePrimaryBtnClick"
    @secondary-btn-click="handleSecondaryBtnClick"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
        urgent
      />
    </template>
    <loan-info-header :task="task" />
    <div
      v-if="!assignmentCompleted"
      class="sn-body-1 mx-6 mt-6"
    >
      Document awaiting your signature:
    </div>
    <div class="doc-border ma-6 pa-4 d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <div class="circle">
          <v-icon
            size="25px"
            color="snPrimary"
          >
            $snPen
          </v-icon>
        </div>
        <div class="px-6">
          <div class="sn-body-1 pb-2">
            Disclosure
          </div>
          <div class="sn-body-2 sn-text-primary-01">
            {{ disclosureSubtitle }}
          </div>
        </div>
      </div>
      <sn-btn
        v-if="showTaskButton"
        :text="disclosureActionText"
        type="text"
        size="standard"
        @click="handleDisclosureAction"
      />
    </div>
    <sn-pdf-viewer
      v-if="showDisclosureModal"
      :dialog="showDisclosureModal"
      :pdfs="[disclosureAssignment]"
      :title="`Disclosure for ${actorName}`"
      @close-dialog="showDisclosureModal = false"
      @input="showDisclosureModal = false"
    />
    <wetsign-modal
      v-model="showWetsignModal"
      :title="disclosureAssignmentName"
      :subtitle="disclosureAssignmentStatus"
      :upload-url="`/api/v1/assignments/${disclosureAssignmentGuid}`"
      :download-doc-name="wetSignDownloadFileName"
      :download-url="disclosureAssignmentImageUrl"
      @close="showWetsignModal = false"
    />
    <sn-modal
      v-model="showDeactivateAssignmentModal"
      title="Delete sign disclosure task?"
      type="decision"
      primary-text="Delete"
      :loading="deactivatingAssignment"
      warning-text="This action cannot be undone"
      warning-icon="sn-icon-warning"
      warning-button
      @submit="deactivateAssignment"
    >
      <div class="sn-body-1">
        Once deleted this task will no longer be available to complete. Are you sure you want to delete the
        <span class="sn-subtitle-1">Sign disclosure</span>
        task?
      </div>
    </sn-modal>
    <sn-two-factor-auth-dialog
      v-model="showTwoFactorAuthModal"
      :event-data="twoFactorEventData"
      @dialogClosed="showTwoFactorAuthModal = false"
    />
  </sn-side-panel>
</template>

<script>
import WetsignModal from '~/components/loanOfficerApp/loanDetails/tasks/taskTypes/disclosurePackage/WetsignModal'
import SnTwoFactorAuthDialog from '~/components/common/v2/snTwoFactorAuthDialog'
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import snUserService from '~/services/snUserService.js'
import { disclosureTaskQuery } from '~/components/loanOfficerApp/dashboard/gql/dashboardQueries.gql'
import { deactivateDisclosureAssignment } from '~/components/loanOfficerApp/Queries/disclosureQueries.gql'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DisclosureSidePanel',
  components: {
    WetsignModal,
    SnTwoFactorAuthDialog,
    SidePanelSubtitle,
    LoanInfoHeader
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      disclosureAssignment: {},
      loadingDisclosure: 0,
      loadingMutation: false,
      showDisclosureModal: false,
      showDeactivateAssignmentModal: false,
      deactivatingAssignment: false,
      showTwoFactorAuthModal: false,
      twoFactorEventData: null,
      showWetsignModal: false,
      wetSignDownloadFileName: ''
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'getPermissions',
      currentUserEmail: 'getCurrentUserEmail'
    }),
    ...mapGetters('Dashboard', {
      servicerId: 'getServicerProfileId',
      isAssignedLO: 'getIsAssignedLO'
    }),
    chipText () {
      return this.task?.resolvedAt ? `Completed on ${this.$moment(this.task?.resolvedAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolvedAt).format('h:mm A')}` : 'Urgent'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolvedAt
    },
    assignmentCompleted () {
      return this.disclosureAssignment?.completed
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      const incompleteText = this.disclosureAssignment?.userCanDeactivate && !this.assignmentCompleted ? 'Delete' : ''
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : incompleteText,
        prependIcon: this.taskCompleted ? '' : '$snTrash',
        iconSize: 24,
        loading: this.loadingMutation
      }
    },
    actorName () {
      return this.task?.actorName
    },
    disclosureSubtitle () {
      if (this.assignmentCompleted) {
        return `Signed on ${this.$moment(this.disclosureAssignment?.completedAt).format('MMMM DD, YYYY')} by ${this.disclosureAssignment?.assigneeName}`
      } else {
        return `Uploaded on ${this.$moment(this.disclosureAssignment?.createdAt).format('MMMM DD, YYYY')}`
      }
    },
    disclosureActionText () {
      if (this.assignmentCompleted) return 'View'
      return this.disclosureAssignment?.actionType === 'view' ? 'View' : this.isAssignedLO ? 'Track' : 'Sign'
    },
    showTaskButton () {
      if (this.disclosureAssignment?.actionType === 'view') return true
      if (this.isAssignedUser) return true
      if (this.permissions?.isAdmin) return true
      if (this.permissions?.isOrgAdmin && !this.disclosureAssignment?.disclosurePackage?.allowAdminsToSignDisclosuresForServicer) return false
      return true
    },
    isAssignedUser () {
      return this.disclosureAssignment?.assigneeEmail === this.currentUserEmail
    },
    disclosureAssignmentName () {
      return this.disclosureAssignment?.name || ''
    },
    disclosureAssignmentStatus () {
      return this.disclosureAssignment?.status || ''
    },
    disclosureAssignmentGuid () {
      return this.disclosureAssignment?.guid
    },
    disclosureAssignmentImageUrl () {
      return this.disclosureAssignment?.imageUrl || ''
    }
  },
  created () {
    this.$root.$on('twoFactorAuthComplete', this.handleTwoFactorAuthComplete)
  },
  destroyed () {
    this.$root.$off('twoFactorAuthComplete', this.handleTwoFactorAuthComplete)
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete']),
    ...mapMutations([
      'updatePermissions',
      'setToast'
    ]),
    handlePrimaryBtnClick () {
      this.toggleCompleteStatus()
    },
    handleSecondaryBtnClick () {
      if (this.taskCompleted) {
        this.toggleCompleteStatus()
      } else {
        this.showDeactivateAssignmentModal = true
      }
    },
    async toggleCompleteStatus () {
      this.loadingMutation = true
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: this.servicerId
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    },
    deactivateAssignment () {
      this.deactivatingAssignment = true
      this.$apollo.mutate({
        mutation: deactivateDisclosureAssignment,
        variables: {
          guid: this.disclosureAssignment.guid
        }
      }).then(() => {
        this.deactivatingAssignment = false
        this.setToast({
          active: true,
          event: 'success',
          message: 'Disclosure assignment deleted'
        })
        this.$emit('reload-tasks')
        this.$emit('input', false)
      })
    },
    handleDisclosureAction () {
      if (this.permissions.isTwoFactorAuthenticated || !this.permissions.requireDisclosures2FA) {
        if (this.assignmentCompleted) {
          this.showDisclosureModal = true
        } else if (this.isAssignedLO) {
          window.location.href = `/servicer_loans/${this.servicerId}/disclosure_package_tool?package_guid=${this.disclosureAssignment?.disclosurePackage?.guid}`
        } else {
          let fileName = 'document'
          switch (this.disclosureAssignment.actionType) {
            case 'enote':
            case 'esign':
            case 'ron':
            case 'docmagic':
            case 'docutech':
            case 'idsdoc':
              if (this.disclosureAssignment.imageUrl) {
                window.location.assign(this.disclosureAssignment.imageUrl)
              } else {
                this.setToast({
                  active: true,
                  event: 'error',
                  message: 'This package cannot be signed right now. Try again later'
                })
              }
              break
            case 'view':
              this.showDisclosureModal = true
              break
            default:
              fileName = this.disclosureAssignment?.imageUrl?.split('/').pop()
              if (fileName) {
                this.wetSignDownloadFileName = fileName
              }
              this.showWetsignModal = true
          }
        }
      } else {
        this.startTwoFactorAuth({ assignment: this.disclosureAssignment })
      }
    },
    startTwoFactorAuth (eventData) {
      this.twoFactorEventData = eventData
      if (eventData.assignment) {
        if (this.permissions.isTwoFactorAuthenticated || !this.permissions.requireDisclosures2FA) {
          this.handleDisclosureAction()
        } else {
          this.showTwoFactorAuthModal = true
        }
      }
    },
    async handleTwoFactorAuthComplete (data) {
      if (data.eventData && data.eventData.assignment) {
        this.updatePermissions({
          isTwoFactorAuthenticated: true
        })
        const resp = await snUserService.getEntityRedirect(data.eventData.assignment.guid)
        this.disclosureAssignment.imageUrl = resp.data.url
        this.handleDisclosureAction()
      }
    }
  },
  apollo: {
    disclosureAssignment: {
      query: disclosureTaskQuery,
      variables () {
        return {
          disclosureAssignmentGuid: this.task?.task_object_guid || ''
        }
      },
      loadingKey: 'loadingDisclosure',
      skip () {
        return !this.task?.task_object_guid
      },
      async result ({ data }) {
        const assignment = data.disclosureAssignment
        const resp = await snUserService.getEntityRedirect(assignment.guid)
        const imageUrl = resp.data.url
        assignment.imageUrl = imageUrl
        this.disclosureAssignment = assignment
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';
.doc-border {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 4px;
  background-color: $sn-gray-01;
}

</style>
