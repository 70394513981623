import { handleError } from './handle_error'

/*
TODO: start enforcing stricter types on the store

interface Context {
  commit: Commit;
  getters: Getter;
  state: State;
}

interface Payload {
  mutation: GraphQLMutation;
  resource: string;
  responseFields: string[];
  errorField?: string;
  meta?: Meta;
}

interface Query {
  query: Mutation;
  variables?: object;
};

type Options = {
  client?: ApolloClient<unknown>;
  clientOptions?: Partial<ApolloClientOptions<unknown>>;
  override?: {
    [key: string]: (params: any) => QueryResult;
  }
}

TODO: Provider should not be in an action, need to extract it out
TODO: Apollo needs to be in a dataProvider

*/

const unwrap = (obj, path = []) => {
  // responseFields is used to dig into the object we get back from the query.
  // As an example, if responseFields is ['orderAus', 'findingRecord'], we will use it
  // like this: data[orderAus][findingRecord]
  let result = obj

  path.forEach(field => {
    result = result[field]
  })

  return result
}

const parseResponse = (props) => {
  const {
    commit,
    data,
    getters,
    identifier = 'guid',
    meta,
    resource,
    responseFields = []
  } = props

  const result = unwrap(data, responseFields)
  const list = getters.getRecordList(resource)
  const record = list.find((record) => record[identifier] === result[identifier])

  if (record) {
    // record exists
    // TODO: If the record exists should we update?
  } else {
    commit('addRecord', { resource, record: result })

    // call onSuccess callback
    if (meta?.onSuccess && typeof meta?.onSuccess === 'function') {
      meta.onSuccess(result)
    }
  }
}

const handleFinally = ({ commit, meta, resource }) => {
  commit('setIsActionLoading', { resource, isLoading: false })

  // call onFinally callback
  if (meta?.onFinally && typeof meta?.onFinally === 'function') {
    meta.onFinally()
  }
}

const sanitizeVariables = ({
  errorField,
  meta,
  responseFields,
  ...rest
}) => ({
  ...rest
})

export const create = async ({ context, dataProvider, payload }) => {
  const { commit } = context
  const {
    // client, TODO
    // query, TODO
    mutation,
    resource,
    ...rest
  } = payload

  commit('setIsActionLoading', { resource, isLoading: true })

  // TODO: get variables a different way.
  // Anything else passed in rest will go on to dataProvider
  const query = {
    mutation,
    variables: sanitizeVariables(rest)
  }

  return dataProvider
    .mutate(query)
    .then(({ data }) => parseResponse({ data, ...context, ...payload }))
    .catch((error) => handleError({ error, ...context, ...payload }))
    .finally(() => handleFinally({ ...context, ...payload }))
}
