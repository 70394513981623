<template>
  <div class="sn-background-gray-01 py-6 px-6 two-column-grid">
    <div class="d-flex align-center full-width">
      <div class="circular-icon mr-2 flex-shrink-0">
        <v-icon
          size="25px"
          color="snBrand03"
        >
          {{ loanNumber ? '$snFolder' : '$snDocumentImage' }}
        </v-icon>
      </div>
      <div class="text-truncate">
        <div class="sn-caption sn-text-primary-01 pb-2">
          {{ loanNumber ? 'Loan #' : 'Loan application' }}
        </div>
        <sn-btn
          v-if="showLoanOrLoanAppInfo"
          type="text"
          :text="loanNumber || 'View details'"
          size="small"
          :href="loanDetailsHref"
        />
        <div
          v-else
          class="sn-body-2"
        >
          Not assigned
        </div>
      </div>
    </div>
    <div class="d-flex align-center full-width">
      <sn-avatar
        v-if="borrowerName"
        :initials="initials"
        class="mr-2"
      />
      <div
        v-else
        class="circular-icon mr-2 flex-shrink-0"
      >
        <v-icon
          size="25px"
          color="snBrand03"
        >
          {{ '$snPerson' }}
        </v-icon>
      </div>
      <div class="text-truncate">
        <div class="sn-caption sn-text-primary-01 pb-2">
          Primary borrower
        </div>
        <sn-btn
          v-if="borrowerName"
          type="text"
          :text="borrowerName"
          size="small"
          text-case="no-format"
          :href="appUsersHref"
        />
        <div
          v-else
          class="sn-body-2"
        >
          Not assigned
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoanInfoHeader',
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    initials () {
      const nameArr = this.borrowerName?.split(' ')
      return (nameArr.length >= 2) ? nameArr[0][0] + nameArr[1][0] : ''
    },
    loanNumber () {
      return this.task?.loan?.loan_number
    },
    loanAppGuid () {
      return this.task?.userLoanApp?.guid
    },
    borrowerName () {
      return this.task?.actorName || ''
    },
    loanDetailsHref () {
      let query = ''
      if (this.task?.loan?.guid) {
        query = `?loanGuid=${this.task.loan.guid}`
      } else if (this.task?.userLoanApp?.guid) {
        query = `?loanAppGuid=${this.task.userLoanApp.guid}`
      }
      return `/servicer_loans/${this.$route.params.servicer_id}/loan_details${query}`
    },
    appUsersHref () {
      return `/loan_officer/${this.$route.params.servicer_id}/contacts?tab=app_users`
    },
    showLoanOrLoanAppInfo () {
      if (this.loanNumber) return true
      else if (this.loanAppGuid && this.task?.userLoanApp?.active) return true
      else return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/snui';

.circular-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid $sn-brand-02;
  background-color: $sn-brand-01;
}

.two-column-grid {
  display: grid;
  row-gap: 8px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.full-width {
  width: 100%;
}

</style>
