<template>
  <div>
    <ApolloMutation
      :mutation="rejectLoanDoc"
      :variables="mutationVars"
      @error="sendError"
      @done="changeStatus('rejected')"
    >
      <sn-modal
        slot-scope="{ mutate, loading }"
        :value="show && isRejectDoc"
        :title="getTitle"
        :loading="loading"
        primary-text="Reject"
        secondary-text="cancel"
        type="decision"
        @submit="mutate"
        @input="$emit('cancel')"
      >
        <div class="pt-2 pb-2 sn-body-1">
          Message (Optional)
        </div>
        <sn-textarea
          v-model="message"
          placeholder="Type a reason for rejection"
          no-resize
          :rows="3"
        />
      </sn-modal>
    </ApolloMutation>
    <ApolloMutation
      :mutation="acceptLoanDoc"
      :variables="mutationVars"
      @error="sendError"
      @done="changeStatus('accepted')"
    >
      <sn-modal
        slot-scope="{ mutate, loading }"
        :value="show && isAcceptDoc"
        :title="getTitle"
        :loading="loading"
        primary-text="Approve"
        secondary-text="cancel"
        type="decision"
        @submit="mutate"
        @input="$emit('cancel')"
      >
        <span>
          This document was rejected by you on {{ formattedDate }} and {{ loanOwnerName }} was notified. The document has not changed since it was rejected.
        </span>
      </sn-modal>
    </ApolloMutation>
  </div>
</template>

<script>
import { rejectLoanDoc, acceptLoanDoc } from '~/components/loanOfficerApp/loanDetails/tasks/gql/taskMutations.gql'
import { mapGetters } from 'vuex'

export default {
  name: 'AcceptRejectLoanDoc',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    doc: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      message: '',
      rejectLoanDoc,
      acceptLoanDoc,
      isRejectDialog: false,
      isAcceptDialog: false
    }
  },
  computed: {
    ...mapGetters({
      loanOwnerName: 'getLoanOwnerFullName'
    }),
    formattedDate () {
      if (this.doc?.updated_at === null) {
        return ''
      }
      return this.$moment(this.doc.updated_at).format('MMM D, YYYY')
    },
    isRejectDoc () {
      return this.doc.status !== 'rejected'
    },
    isAcceptDoc () {
      return this.doc.status === 'rejected'
    },
    getTitle () {
      let title = ''
      if (this.isRejectDoc) {
        title = `Reject ${this.doc?.name}?`
      } else {
        title = 'Approve a rejected document?'
      }
      return title
    },
    mutationVars () {
      return {
        loan_doc: {
          id: this.doc.id,
          message: this.message
        }
      }
    }
  },
  methods: {
    changeStatus (status) {
      // don't update if not change
      this.doc.status = status
      this.$emit('status-changed', this.doc)
    },
    sendError (err) {
      err.displayMessage = err.displayMessage || 'There was a problem updating the document'
      this.$emit('error', err)
    }
  }
}
</script>

<style>

</style>
