import getters from './Lo1003Getters.js'
import actions from './Lo1003Actions.js'
import mutations from './Lo1003Mutations.js'

const store = {
  namespaced: true,

  state: () => ({
    lo1003Form: { structure: [], values: {} },
    hasCoborrower: false,
    currentPageIndex: 0,
    inMobileView: false,
    inMobileApp: false,
    invalidFields: [],
    visibleFields: [],
    focusedPage: '',
    focusedField: '',
    mobileFocusedField: '',
    focusedSkeletonField: {},
    redirectQueryParam: '',
    servicerActivationCode: '',
    isLoanAppLocked: false,
    loanAppCompletedPercent: 0,
    validateSection: false,
    navAction: {},
    selectedService: '',
    shouldScrollToError: false,
    fieldToScrollTo: null,
    isSaving: false,
    isSubmitting: false,
    pageIsLoading: true,
    valuesWereSaved: false,
    valuesHaveChanged: false,
    loanApp: {},
    servicerProfile: {},
    saveError: {
      error: false,
      errorMessage: ''
    },
    expandedNavSection: '',
    showCreditAuthMissingFields: false,
    showShareInfoMissingFields: false,
    showEconsentMissingFields: false,
    showNotesSidePanel: false,
    unsavedNoteValue: '',
    noteValue: '',
    showChatSidePanel: false,
    hideMobileFooter: false,
    orgAdminEditAllowed: false,
    currentSearchResults: [],
    isOrderAusModalOpen: false,
    showRunServiceModal: false
  }),
  getters,
  actions,
  mutations
}

export default store
