<template>
  <div>
    <FileUploader
      :value="value"
      :title="title"
      :subtitle="subtitle"
      :upload-url="uploadUrl"
      :is-disclosure-doc="true"
      size="fullscreen"
      @change="$emit('assignment-completed')"
      @input="$emit('close')"
    >
      <template v-slot:upperContent>
        <div class="sn-subtitle-1 mb-4">
          Step 1: Download and sign
        </div>
        <div class="d-flex download-container align-center px-4 py-6">
          <span class="align-center mr-4">
            <sn-avatar
              background-class="sn-background-brand-01"
              icon="$snDocument"
              :icon-style="iconStyle"
              :tile="false"
            />
          </span>
          <span class="sn-body-2">
            {{ downloadDocName }}
          </span>
          <v-spacer />
          <sn-btn
            type="secondary"
            text="Download"
            size="standard"
            @click="downloadDocument"
          />
        </div>
        <v-divider class="divider my-10" />
        <div class="sn-subtitle-1 mb-4">
          Step 2: Upload the signed document
        </div>
      </template>
    </FileUploader>
  </div>
</template>

<script>
import FileUploader from './FileUploader'
import { snBrand } from '~/assets/css/snui/_snColorVariables.scss'

export default {
  name: 'WetsignModal',
  components: {
    FileUploader
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    uploadUrl: {
      type: String,
      required: true
    },
    downloadDocName: {
      type: String,
      required: true
    },
    downloadUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    iconStyle () {
      return `color: ${snBrand}`
    }
  },
  methods: {
    async downloadDocument () {
      await fetch(this.downloadUrl)
        .then(resp => resp.arrayBuffer())
        .then(resp => {
          const file = new Blob([resp], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.download = this.downloadDocName
          link.href = URL.createObjectURL(file)
          link.target = '_blank'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          // Remove the file from memory after a little time has passed
          setTimeout(() => URL.revokeObjectURL(link.href), 5000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/snui/index.scss';
.download-container {
  border: 1px solid $sn-gray-04;
  border-radius: 8px;
}
.divider {
  background-color: $sn-gray-04;
}
</style>
