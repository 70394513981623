/* eslint no-console: 0, no-unused-vars: 0, no-undef: 0 */
import apolloClient from '~/util/apolloClient'
import Partner from '~/components/partnerApp/PartnerApp.vue'
import Router from 'vue-router'
import axios from '~/util/axios'
import Sentry from '~/util/sentry'
import datadog from '~/util/datadog.js'
import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import vuetify from '~/util/vuetify'
import { VueMaskDirective } from 'v-mask'
import '~/assets/css/deprecatedVuetify2prefix.scss'
// eslint-disable-next-line import/no-unresolved
import { store } from '~/components/loanOfficerApp/store'
import {
  snAlert,
  snAvatar,
  snBtn,
  snIllustration,
  snInfiniteLoader,
  snLabel,
  snModal,
  snSearch,
  snSelect,
  snTextarea,
  snTooltip,
  snToast
} from '@simplenexus/snui'
// eslint-disable-next-line import/no-unresolved
import '@simplenexus/snui/dist/assets/index.css'

Vue.component('snAlert', snAlert)
Vue.component('snAvatar', snAvatar)
Vue.component('snBtn', snBtn)
Vue.component('snIllustration', snIllustration)
Vue.component('snInfiniteLoader', snInfiniteLoader)
Vue.component('snLabel', snLabel)
Vue.component('snModal', snModal)
Vue.component('snSearch', snSearch)
Vue.component('snSelect', snSelect)
Vue.component('snTextarea', snTextarea)
Vue.component('snTooltip', snTooltip)
Vue.component('snToast', snToast)

const { VueApollo, provider } = apolloClient()
datadog({ packName: 'partnerPack' })

Vue.prototype.$axios = axios

if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
  Vue.config.devtools = true
}

Vue.use(Router)
Vue.use(VueMoment)
Vue.use(VueApollo)
Vue.use(Vuex)

Vue.directive('mask', VueMaskDirective)

Vue.prototype.$sentry = Sentry([{ key: 'pack', value: 'Partner' }])

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/partners/loans',
      name: 'Loans',
      component: () =>
        import('~/components/partnerApp/loans/Loans')
    },
    {
      path: '/partner/:lo_email/:partner_email?',
      component: () =>
        import('../components/partnerApp/landingPage/PartnerLandingPage.vue')
    },
    {
      path: '/partners/settings/:page',
      component: () =>
        import('../components/partnerApp/settings/Settings.vue')
    },
    {
      path: '/partners/recent_activity/:user_id',
      component: () =>
        import('../components/partnerApp/settings/recentActivityPage.vue')
    },
    {
      path: '/partners/doc_inbox',
      component: () =>
        import('../components/partnerApp/docs/Docs.vue')
    },
    {
      path: '/partners/calculator',
      component: () =>
        import('../components/partnerApp/calculator/Calculator.vue')
    },
    {
      path: '/partners/chat/',
      component: () =>
        import('../components/chat/v2/chatPage.vue')
    }
  ]
})

const app = new Vue({
  el: '#app-container',
  apolloProvider: provider,
  router: router,
  vuetify,
  store,
  render: createEle => createEle(Partner)
})
