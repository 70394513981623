<template>
  <sn-modal
    :value="value"
    :title="title"
    :sub-title="subtitle"
    :size="size"
    type="decision"
    primary-text="Upload"
    secondary-text="Cancel"
    :data-cy="dataCy"
    :loading="loading"
    style="overscroll-behavior: contain"
    @submit="uploadFiles()"
    @input="cancel"
  >
    <template v-if="size === 'fullscreen'">
      <v-card class="mt-8 py-8 px-12 modal-card sn-shadow-depth-1">
        <slot name="upperContent" />
        <sn-file-input
          class="file-input"
          accept="image/jpeg, application/pdf, image/png, .jpg, .jpeg, .png, .pdf"
          :processing="loading"
          :may-remove="true"
          :may-edit-name="true"
          multiple
          :height="184"
          @files="handleFiles"
          @file-name-changed="changeFileName"
        />
      </v-card>
      <div class="mt-8 mb-6 d-flex justify-center">
        <sn-btn
          :disabled="!fileNames.length"
          :loading="loading"
          :text="`Upload${fileNames.length ? ` (${fileNames.length})` : ''}`"
          @click="uploadFiles()"
        />
      </div>
    </template>
    <sn-file-input
      v-else
      class="mt-2 mb-6 file-input"
      accept="image/jpeg, application/pdf, image/png, .jpg, .jpeg, .png, .pdf"
      :processing="loading"
      :may-remove="true"
      :may-edit-name="true"
      multiple
      :height="184"
      @files="handleFiles"
      @file-name-changed="changeFileName"
    />
  </sn-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import { cypressMixin } from '@simplenexus/snui'

export default {
  name: 'FileUploader',
  mixins: [cypressMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    uploadUrl: {
      type: String,
      required: true
    },
    additionalFormData: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    isLoanDoc: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'standard'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    isDisclosureDoc: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: [],
      filesComplete: 0,
      fileNames: [],
      fileGuids: [],
      fileProgress: [],
      httpAction: null,
      replacingLoanDocGuids: {},
      rules: {
        nameLength: (value) => { return value.length === 0 ? 'File must have a name' : true },
        validCharacterTypes: (value) => /^[\w- ]+$/.test(value)
          ? true
          : 'Filename can not contain special characters'
      },
      submitted: false,
      uploadProgress: 0,
      loading: false,
      errorOccurred: false
    }
  },
  methods: {
    ...mapMutations({
      displayToast: 'setToast'
    }),
    cancel () {
      this.$emit('input', false)
    },
    addFile (file) {
      this.files.push(file)
      const fileName = this.createFilename(file)
      this.fileNames.push(fileName)
      this.fileGuids.push(fileName.localGuid)
    },
    removeFile (fileGuid) {
      const index = this.fileNames.findIndex(n => n.localGuid === fileGuid)
      this.fileNames.splice(index, 1)
      this.files.splice(index, 1)
      this.fileGuids.splice(index, 1)
    },
    handleFiles (files) {
      if (!files.length && !this.files.length) { return }
      if (files.length > this.files.length) {
        let extras = files
        if (this.files.length) {
          var tmp = this.files
          extras = files.filter(function (obj) { return tmp.indexOf(obj) === -1 })
        }
        for (const file of extras) {
          this.addFile(file)
        }
      } else {
        let extras = this.files
        if (files.length) {
          extras = this.files.filter(function (obj) { return files.indexOf(obj) === -1 })
        }
        for (const file of extras) {
          this.removeFile(file.localGuid)
        }
      }
    },
    changeFileName (fileGuid, newName) {
      const fileName = this.fileNames.find(file => file.localGuid === fileGuid)
      this.$set(fileName, 'name', this.getFilenameAndSuffix(newName)[0])
      this.$set(fileName, 'fullName', newName + '.' + fileName.suffix)
    },
    createFilename (file) {
      return {
        name: this.getFilenameAndSuffix(file.name)[0],
        isEditing: false,
        isUploaded: false,
        localGuid: file.guid,
        suffix: this.getFilenameAndSuffix(file.name)[1],
        fullName: file.name
      }
    },
    getFilenameAndSuffix (name) {
      var segments = name.split('.')
      let newName = ''
      if (segments.length === 1) {
        // No extension
        newName = segments[0]
      } else if (segments.length === 2) {
        // Single period
        newName = segments[0]
      } else if (segments.length > 2) {
        // Multiple periods
        newName = segments.slice(0, segments.length - 1).join('_')
      }

      const regex = /[^\w\s]/gi
      return [newName.replace(regex, '_'), segments[segments.length - 1]]
    },
    handleError (err) {
      this.errorOccurred = true
      this.displayToast({
        active: true,
        message: err.displayMessage || 'There was a problem uploading.',
        description: err.description || '',
        event: 'error'
      })
    },
    handleUploadFinished () {
      this.loading = false
      this.$emit('uploadFinished')
      if (!this.errorOccurred) {
        this.$emit('input', false)
      }
    },
    uploadDisclosures (that) {
      const promises = []
      let hasFileError = false

      if (this.files.length > 1) {
        const validationPromises = []

        for (const [index, file] of this.files.entries()) {
          this.$set(this.files[index], 'loading', true)

          validationPromises.push(this.validateFile(file, this.fileNames[index].fullName)
            .catch((err) => {
              err.displayMessage = `There is a problem with your file: ${this.fileNames[index].fullName}`
              err.description = err.message
              hasFileError = true
              this.handleError(err)
              throw err
            })
          )
        }

        promises.push(Promise.all(validationPromises)
          .then((responses) => {
            const data = new FormData()
            data.append('multipart', true)
            data.append('origin', 'website')
            data.append('user_agent', navigator.userAgent)

            for (const [index, file] of this.files.entries()) {
              this.$set(this.files[index], 'loading', true)
              data.append(`file${index}`, file)
              data.append(`name${index}`, this.fileNames[index])
            }

            for (const key of Object.keys(this.additionalFormData)) {
              if (!Object.keys(data).includes(key)) {
                data.append(`${key}`, this.additionalFormData[key])
              }
            }

            const config = {
              onUploadProgress (progressEvent) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                that.$set(that.fileProgress, 0, progress)
                if (progress === 100) {
                  that.$emit('processing')
                }
              }
            }
            const uploadPromise = this.$axios.post(this.uploadUrl, data, config)
            promises.push(uploadPromise)

            return uploadPromise
          })
          .catch((err) => {
            for (const file of this.files) {
              file.loading = false
            }
            this.$sentry.captureException(err)
            this.$set(this.files[0], 'error', err.message)
            return err
          })
          .finally(() => {
            this.filesComplete = Array.isArray(this.files) ? this.files.length : 0
          }))
      } else {
        const file = this.files[0]
        this.$set(this.files[0], 'loading', true)

        promises.push(this.validateFile(file, this.fileNames[0].fullName)
          .catch((err) => {
            err.displayMessage = `There is a problem with your file: ${this.fileNames[0].fullName}`
            err.description = err.message
            hasFileError = true
            this.handleError(err)
            throw err
          })
          .then(() => {
            const data = new FormData()
            data.append('image', file)
            data.append('name', this.fileNames[0])
            data.append('origin', 'website')
            data.append('user_agent', navigator.userAgent)

            for (const key of Object.keys(this.additionalFormData)) {
              if (!Object.keys(data).includes(key)) {
                data.append(`${key}`, this.additionalFormData[key])
              }
            }

            const config = {
              onUploadProgress (progressEvent) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                that.$set(that.fileProgress, 0, progress)
                if (progress === 100) {
                  that.$emit('processing')
                }
              }
            }
            const uploadPromise = this.$axios.post(this.uploadUrl, data, config)
            promises.push(uploadPromise)

            return uploadPromise
          })
          .then((response) => {
            this.$set(this.files[0], 'loading', false)
            this.$set(this.files[0], 'done', true)
            this.$emit('uploaded', file)

            return response
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
              this.$set(this.files[0], 'error', err.response.data.error)
            } else if (err.message) {
              this.$set(this.files[0], 'error', err.message)
            }

            this.$set(this.files[0], 'loading', false)

            return err
          })
          .finally(() => {
            this.filesComplete++
          }))
      }
      return {
        promises,
        hasFileError
      }
    },
    uploadLoanDocs (that) {
      const httpActions = []
      const promises = []
      const loanDocGuids = []
      let thisKey = null
      let hasFileError = false
      for (const [index, file] of this.files.entries()) {
        this.$set(this.files[index], 'loading', true)
        thisKey = Object.keys(this.replacingLoanDocGuids).find(k => this.replacingLoanDocGuids[k] === index)
        if (thisKey) {
          httpActions[index] = 'put'
          loanDocGuids[index] = thisKey
        } else {
          httpActions[index] = 'post'
          loanDocGuids[index] = null
        }

        promises.push(this.validateFile(file, this.fileNames[index].fullName)
          .catch(err => {
            err.displayMessage = `There is a problem with your file: ${this.fileNames[index].fullName}`
            err.description = err.message
            hasFileError = true
            this.handleError(err)
            throw err
          })
          .then(() => {
            const data = new FormData()
            data.append('origin', 'website')
            data.append('user_agent', navigator.userAgent)
            data.append('name', this.fileNames[index].fullName)
            if (this.isLoanDoc) {
              data.append('loan_doc[origin]', 'website')
              data.append('loan_doc[user_agent]', navigator.userAgent)

              if (this.loan && (this.loan.loan_guid || this.loan.guid)) {
                data.append('loan_doc[loan_guid]', this.loan.loan_guid || this.loan.guid)
              }
              if (that.$route.query.loanAppGuid) {
                data.append('loan_doc[loan_app_guid]', that.$route.query.loanAppGuid)
              }
              if (loanDocGuids[index]) {
                data.append('loan_doc[loan_doc_guid]', loanDocGuids[index])
              }
              data.append('loan_doc[image]', file, this.fileNames[index].fullName)
              for (const key of Object.keys(this.additionalFormData)) {
                if (!Object.keys(data).includes(key)) {
                  data.append(`${key}`, this.additionalFormData[key])
                }
              }
            } else {
              data.append('image', file)
            }

            const config = {
              onUploadProgress (progressEvent) {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                that.$set(that.fileProgress, index, progress)
                if (progress === 100) {
                  that.$emit('processing')
                }
              }
            }
            const uploadPromise = this.$axios[httpActions[index]](this.uploadUrl, data, config)
            promises.push(uploadPromise)

            return uploadPromise
          })
          .then((response) => {
            this.$set(this.files[index], 'loading', false)
            this.$set(this.files[index], 'done', true)
            this.$emit('uploaded', file)

            return response
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
              this.$set(this.files[index], 'error', err.response.data.error)
            } else if (err.message) {
              this.$set(this.files[index], 'error', err.message)
            }

            this.$set(this.files[index], 'loading', false)

            return err
          })
          .finally(() => {
            this.filesComplete++
          }))
      }
      return {
        promises,
        hasFileError
      }
    },
    uploadFiles () {
      if (!this.files.length) return

      this.loading = true
      this.errorOccurred = false
      const that = this
      return new Promise((resolve, reject) => {
        if (this.done) return

        this.submitted = true
        let returnObj = {}

        if (this.isDisclosureDoc) {
          returnObj = this.uploadDisclosures(that)
        } else {
          returnObj = this.uploadLoanDocs(that)
        }
        const { promises, hasFileError } = returnObj || {}

        // Invalid filename somewhere... Don't upload the files
        // The promise is rejected in validateFile catch, added here to
        // halt execution of other file uploads
        if (hasFileError) {
          this.filesComplete = 0
          this.files = []
          this.fileNames = []
          this.replacingLoanDocGuids = {}
          return
        }

        const successfullyUploadedResponses = []
        Promise.all(promises)
          .then((responses) => {
            for (const response of responses) {
              if (response instanceof Error) {
                response.displayMessage = response.displayMessage || 'There was a problem uploading the document'
                this.handleError(response)
              } else {
                if (this.isLoanDoc) {
                  const { name } = response.data.loan_doc

                  const fileIndex = this.fileNames.findIndex(fileName => fileName.name.split('.')[0] === name)

                  this.fileNames.pop(fileIndex)
                  this.files.pop(fileIndex)
                }
                successfullyUploadedResponses.push(response)
              }
            }

            this.$emit('change', successfullyUploadedResponses)

            const successfullyUploadedAllDocuments = successfullyUploadedResponses.length === responses.length
            if (successfullyUploadedAllDocuments) {
              this.filesComplete = 0
              this.files = []
              this.fileNames = []
              this.replacingLoanDocGuids = {}
              this.handleUploadFinished()
            }
            return responses
          })
          .catch((err) => {
            // We shouldn't ever reach this since we're reflecting the rejected
            // promises. That way, we wait until all promises are settled.
            this.handleError(err)
            reject(new Error(`FileUploader: failed to reflect rejected promise - ${err}`))
          })
          .finally(() => {
            this.submitted = false
            resolve(successfullyUploadedResponses)
            this.loading = false
          })
      })
    },
    validateFile (file, name) {
      return new Promise((resolve, reject) => {
        // Filename checks
        if (typeof this.rules.nameLength(name) === 'string') {
          reject(new Error('Invalid filename'))
        }

        if (!/\.(pdf|jpg|jpeg|png)$/.test(name.toLowerCase())) {
          reject(new Error('Invalid file extension'))
        }
        resolve()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    min-width: 656px;
    border-radius: 16px !important;
  }
</style>
