export default {
  addRecord (state, { resource, record }) {
    state.resources[resource].data.unshift(record)
  },
  setIsPageLoading (state, { resource, isLoading }) {
    state.resources[resource].isPageLoading = isLoading
  },
  setIsActionLoading (state, { resource, isLoading }) {
    state.resources[resource].isActionLoading = isLoading
  },
  setError (state, { resource, error }) {
    state.resources[resource].error = error
  },
  addErrors (state, { resource, error }) {
    state.resources[resource].error = state.resources[resource].error ?? []
    state.resources[resource].error.unshift(...error)
  },
  setData (state, { resource, data }) {
    state.resources[resource].data = data
  },
  setPagedData (state, { resource, data, filteredTotal, pageInfo }) {
    state.resources[resource].data = data
    state.resources[resource].filteredTotal = filteredTotal
    state.resources[resource].pageInfo = pageInfo
  },
  setPermissions (state, { permissions }) {
    state.resources.permissions = permissions
  },
  setPermission (state, { resourceField, permission }) {
    state.resources.permissions[resourceField] = permission
  },
  updateRecord (state, { resource, newRecord, index }) {
    state.resources[resource].data.splice(index, 1, newRecord)
  },
  setState (state, { resource, status }) {
    state.resources[resource].state = status
  },
  setItem (state, { resource, item }) {
    state.resources[resource].item = item
  },
  setCancelState (state, { resource, cancelState }) {
    state.resources[resource].cancelState = cancelState
  },
  removeErrors (state, { resource, field, value }) {
    const filteredErrors = state.resources[resource].error.filter(record => record[field] !== value)
    state.resources[resource].error = filteredErrors
  }
}
