export default {
  setCurrentModal (state, payload) {
    state.currentModal = payload
  },
  setCurrentPdfViewer (state, payload) {
    state.currentPdfViewer = payload
  },
  setCurrentSidePanel (state, payload) {
    state.currentSidePanel = payload
  },
  addLoadedTask (state, payload) {
    state.loadedTasks = new Set(state.loadedTasks.add(payload))
  },
  clearLoadedTasks (state) {
    state.loadedTasks = new Set()
  },
  setCurrentTaskGuid (state, payload) {
    state.currentTaskGuid = payload
  },
  setCurrentTaskListOwnerGuid (state, payload) {
    state.currentTaskListOwnerGuid = payload
  }
}
