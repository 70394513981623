const store = {
  namespaced: true,

  state: () => ({
    quickLinks: [],
    quickLinkTypes: [],
    profilePicture: null,
    companyLogo: null,
    partnerProfileFormValid: true,
    shouldValidatePartnerForm: false,
    selectedLo: {},
    searchText: '',
    loanOfficers: {
      filteredTotal: 0,
      data: []
    },
    tmPartnersEnabled: true
  }),
  getters: {
    getTmPartnersEnabled (state) {
      return state.tmPartnersEnabled
    },
    getLoanOfficers (state) {
      return state.loanOfficers
    },
    getSearchText (state) {
      return state.searchText
    },
    getSelectedLo (state) {
      return state.selectedLo
    },
    getQuickLinks (state) {
      return state.quickLinks
    },
    getQuickLinkTypes (state) {
      return state.quickLinkTypes
    },
    getQuickLink: (state) => (index) => {
      return state.quickLinks[index]
    },
    getProfilePicture (state) {
      return state.profilePicture
    },
    getCompanyLogo (state) {
      return state.companyLogo
    },
    getPartnerProfileFormValid (state) {
      return state.partnerProfileFormValid
    },
    getShouldValidatePartnerForm (state) {
      return state.shouldValidatePartnerForm
    }
  },
  mutations: {
    setTmPartnersEnabled (state, payload) {
      state.tmPartnersEnabled = payload
    },
    setLoanOfficers (state, payload) {
      state.loanOfficers = payload
    },
    setSearchText (state, payload) {
      state.searchText = payload
    },
    setSelectedLo (state, payload) {
      state.selectedLo = payload
    },
    setQuickLinkTypes (state, linkTypes) {
      state.quickLinkTypes = linkTypes
    },
    addBlankQuickLink (state) {
      state.quickLinks.push({ linkType: null, linkText: '', url: '' })
    },
    updateQuickLink (state, { link, index }) {
      if (!state.quickLinks[index]) return
      state.quickLinks[index] = link
    },
    removeQuickLink (state, index) {
      state.quickLinks.splice(index, 1)
    },
    setProfilePicture (state, img) {
      state.profilePicture = img
    },
    setCompanyLogo (state, img) {
      state.companyLogo = img
    },
    clearQuickLinks (state) {
      state.quickLinks = []
    },
    setPartnerProfileFormValid (state, val) {
      state.partnerProfileFormValid = val
    },
    toggleShouldValidatePartnerForm (state) {
      state.shouldValidatePartnerForm = !state.shouldValidatePartnerForm
    }
  },
  actions: {
    setQuickLinkTypes (context, linkTypes) {
      if (!linkTypes) {
        context.commit('setQuickLinkTypes', [
          { id: '57', name: 'What my clients are saying' },
          { id: '9', name: 'Home search' },
          { id: '54', name: 'My listings' }
        ])
        return
      }

      const links = linkTypes.map((l) => {
        return { id: l.link_type_id, name: l.link_type.name }
      })
      context.commit('setQuickLinkTypes', links)
    }
  }

}

export default store
