export const serviceEnums = {
  CREDIT: {
    type: 'Credit',
    buttonText: 'Run Credit',
    icon: 'sn-icon-credit-card'
  },
  PRICING: {
    type: 'Pricing',
    buttonText: 'Run Pricing',
    icon: 'sn-icon-bar-chart-rise'
  },
  AUS: {
    type: 'AUS',
    buttonText: 'Run AUS',
    icon: 'sn-icon-document-sign'
  },
  PRE_APPROVAL: {
    type: 'Pre-approval',
    buttonText: 'Generate Pre-approval',
    icon: 'sn-icon-mail'
  },
  PRE_QUAL: {
    type: 'Pre-qual',
    buttonText: 'Generate Pre-qual',
    icon: 'sn-icon-mail'
  },
  NONE: {
    type: ''
  }
}

export const creditAuthEnums = {
  BORROWER_CREDIT_AUTH_REQUEST: 'Borrower-Credit-Auth-Request',
  COBORROWER_CREDIT_AUTH_REQUEST: 'Coborrower-Credit-Auth-Request'
}

export const customFormRequestEnums = {
  BORROWER_SHARE_INFO_REQUEST: 'Borrower-Share-Info-Request',
  COBORROWER_SHARE_INFO_REQUEST: 'Coborrower-Share-Info-Request',
  BORROWER_ECONSENT_REQUEST: 'Borrower-Econsent-Request',
  COBORROWER_ECONSENT_REQUEST: 'Coborrower-Econsent-Request'
}

export const mobileEventsEnums = {
  BORROWER_INFORMATION_UPDATED: 'borrowerInformationUpdated',
  VALUES_HAVE_CHANGED: 'valuesHaveChanged',
  FORM_SAVED_RESULT: 'formSavedResult',
  CURRENT_BORROWER_CHANGED: 'currentBorrowerChanged',
  PAGE_INDEXES_UPDATED: 'pageIndexesUpdated',
  FORM_HAS_BEEN_SUBMITTED: 'formHasBeenSubmitted',
  SUBMIT_BUTTON_CLICKED: 'submitButtonClicked'
}

export const noteStateEnums = {
  EMPTY: 'emptyState',
  EDIT: 'editState',
  VIEW: 'viewState'
}
