<template>
  <div class="panel ma-6 pa-6">
    <div class="d-flex align-center">
      <div class="sn-body-2 max-width pr-6">
        {{ submittedText }}
      </div>
      <sn-illustration
        illustration="Success"
        size="sm"
      />
    </div>
    <div class="pt-8">
      <div class="sn-subtitle-2">
        Loan details
      </div>
      <div class="pt-4 two-column-grid">
        <div
          v-for="item in loanDetails"
          :key="item.title"
        >
          <div class="sn-caption sn-text-primary-01">
            {{ item.title }}
          </div>
          <div class="sn-body-1 pt-1">
            {{ item.value || '-' }}
          </div>
        </div>
      </div>
      <sn-btn
        v-if="loanAppActionsMixin_showLoanAppAction"
        class="mt-6"
        size="standard"
        type="secondary"
        text="View loan application"
        :href="loanAppActionsMixin_loanAppActionHref"
        @click="handleViewLoanAppClick"
      />
    </div>
    <sn-pdf-viewer
      :dialog="loanAppActionsMixin_showLoanAppPreview"
      :pdfs="[loanAppActionsMixin_loanApp]"
      :title="`Loan application for ${appUserName}`"
      :subtitle="pdfViewerSubmittedText"
      download-override
      @download="loanAppActionsMixin_showDownloadFormatsModal = true"
      @close-dialog="closeDialog()"
      @input="closeDialog()"
    />
    <loan-app-download-modal
      v-model="loanAppActionsMixin_showDownloadFormatsModal"
      @download="loanAppActionsMixin_onClickDownload"
    />
  </div>
</template>

<script>
import formatCurrency from '~/util/usCurrencyFilter'
import loanAppActionsMixin from '~/components/loanOfficerApp/loanDetails/shared/loanAppActionsMixin.js'
import LoanAppDownloadModal from '~/components/loanOfficerApp/loanDetails/shared/LoanAppDownloadModal'
import { mapGetters } from 'vuex'

export default {
  name: 'LoanAppPanelBody',
  components: {
    LoanAppDownloadModal
  },
  filters: { formatCurrency },
  mixins: [loanAppActionsMixin],
  props: {
    taskEventId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('Dashboard', {
      currentTask: 'getCurrentTask'
    }),
    submittedText () {
      return `Loan application has been submitted${this.loanAppActionsMixin_loanApp?.submitted_by_name ? ' by ' + this.loanAppActionsMixin_loanApp?.submitted_by_name : ''} on ${this.$moment(this.loanAppActionsMixin_loanApp?.submitted_at).format('MMMM DD, YYYY')} at ${this.$moment(this.loanAppActionsMixin_loanApp?.submitted_at).format('h:mm A')}`
    },
    hasLoanProgram () {
      return this.loanAppActionsMixin_loanApp?.loan_term || this.loanAppActionsMixin_loanApp?.amortization_type
    },
    loanDetails () {
      return [
        {
          title: 'Loan amount',
          value: formatCurrency(Math.round(parseFloat(this.loanAppActionsMixin_loanApp?.loan_amount)), false, false)
        },
        {
          title: 'Purchase price',
          value: formatCurrency(Math.round(parseFloat(this.loanAppActionsMixin_loanApp?.purchase_price)), false, false)
        },
        {
          title: 'Loan purpose',
          value: this.loanAppActionsMixin_loanApp?.loan_purpose
        },
        {
          title: 'Loan type',
          value: this.loanAppActionsMixin_loanApp?.loan_type
        },
        {
          title: 'Loan program',
          value: this.hasLoanProgram ? `${this.loanAppActionsMixin_loanApp?.loan_term} ${this.loanAppActionsMixin_loanApp?.amortization_type}` : null
        },
        {
          title: 'Co-borrower',
          value: this.loanAppActionsMixin_loanApp?.co_borrower ? 'Yes' : 'No'
        }
      ]
    },
    pdfViewerSubmittedText () {
      return `Submitted on ${this.$moment(this.loanAppActionsMixin_loanApp?.submitted_at).format('MMM DD, YYYY')} at ${this.$moment(this.loanAppActionsMixin_loanApp?.submitted_at).format('h:mm A')}`
    },
    isAdmin () {
      return this.loanAppActionsMixin_permissions?.isAdmin
    },
    appUserGuid () {
      return this.loanAppActionsMixin_loanApp?.appUser?.guid
    },
    appUserName () {
      return this.loanAppActionsMixin_loanApp?.appUser?.name
    }
  },
  methods: {
    handleViewLoanAppClick () {
      if (!this.currentTask.resolved_at) this.loanAppActionsMixin_taskEventId = this.taskEventId
      this.loanAppActionsMixin_handleLoanAppAction()
    },
    closeDialog () {
      this.loanAppActionsMixin_showLoanAppPreview = false
      this.$emit('pdf-viewed')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';

.panel {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
}

.max-width {
  max-width: 70%;
}

.two-column-grid {
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

</style>
