<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
import brandThemeMixin from '~/components/common/brandThemeMixin.js'
export default {
  mixins: [brandThemeMixin]
}
</script>
