/*
interface GraphQLError {
  message: string;
}
*/

export const extractGqlErrorMessage = (error) => {
  const { 1: errorMessage } = error.message.split('GraphQL error: ')

  return errorMessage
}
