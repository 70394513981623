<template>
  <sn-pdf-viewer
    :dialog="value"
    :pdfs="[report]"
    title="Credit report"
    :subtitle="`Updated: ${$moment(report.updatedDate).format('MMM DD, YYYY')} at ${$moment(report.updatedDate).format('LT')}`"
    disable-icons
    @close-dialog="$emit('input', false)"
    @input="$emit('input', false)"
  />
</template>

<script>
export default {
  name: 'CreditPdfViewer',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
