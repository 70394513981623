import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

import CreditOrderStatusEnum from '~/components/loanOfficerApp/credit/enums/CreditOrderStatusEnum'

const store = {
  state: () => ({
    resources: {
      ausFindings: {
        isPageLoading: false,
        isActionLoading: false,
        data: [],
        error: [],
        pollingId: null
      },
      closingCostItems: {
        isPageLoading: false,
        data: [],
        filteredTotal: 0,
        pageInfo: {},
        error: null,
        summary: {}
      },
      creditReports: {
        isPageLoading: false,
        data: [],
        error: null,
        state: CreditOrderStatusEnum.NOT_ORDERED
      },
      creditLiabilities: {
        data: [],
        error: null,
        cancelState: false
      },
      loans: {
        isPageLoading: false,
        data: [],
        error: null
      },
      loanApps: {
        isPageLoading: false,
        data: [],
        error: null
      },
      permissions: {
        ausEnabled: false,
        duEnabled: false,
        lpaEnabled: false,
        isPageLoading: false
      },
      servicerProfile: {
        item: null
      },
      verificationSummaryList: {
        isPageLoading: false,
        data: [],
        error: null
      },
      verificationReports: {
        isPageLoading: false,
        data: [],
        error: null
      },
      finalVerificationReports: {
        isPageLoading: false,
        isActionLoading: false,
        data: [],
        error: []
      },
      loanBorrower: {
        item: null
      },
      loanCoBorrower: {
        item: null
      }
    }
  }),
  getters,
  actions,
  mutations
}

export default store
