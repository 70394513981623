import getters from './TaskGetters'
import actions from './TaskActions'
import mutations from './TaskMutations'

const store = {
  namespaced: true,

  state: () => ({
    currentModal: '',
    loadedTasks: new Set(),
    currentPdfViewer: '',
    currentSidePanel: '',
    currentTaskGuid: '',
    currentTaskListOwnerGuid: ''
  }),
  getters,
  actions,
  mutations
}

export default store
