export default {
  getCurrentModal: (state) => {
    return state.currentModal
  },
  getCurrentPdfViewer: (state) => {
    return state.currentPdfViewer
  },
  getCurrentSidePanel: (state) => {
    return state.currentSidePanel
  },
  getLoadedTasks: (state) => {
    return state.loadedTasks
  },
  getCurrentTaskGuid: (state) => {
    return state.currentTaskGuid
  },
  getCurrentTaskListOwnerGuid: (state) => {
    return state.currentTaskListOwnerGuid
  }
}
