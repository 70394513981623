<template>
  <sn-modal
    :value="value"
    :title="title"
    type="decision"
    primary-text="Delete"
    warning-button
    warning-icon="$snWarning"
    warning-text="This action cannot be undone"
    :loading="loading"
    @input="$emit('input', false)"
    @submit="removeDoc"
  >
    <div class="sn-body-1">
      Both you and <b>{{ borrowerName }}</b> will no longer have access to this document. Are you sure you want to remove it?
    </div>
  </sn-modal>
</template>

<script>
import { removeDocMutation } from '~/components/loanOfficerApp/Queries/loanDocsQueries.gql'
import { mapMutations } from 'vuex'

export default {
  name: 'DeleteLoanDocModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    document: {
      type: Object,
      required: true
    },
    borrowerName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    title () {
      return `Delete ${this.document.name + ' ' || ''}document?`
    }
  },
  methods: {
    ...mapMutations(['setToast']),
    async removeDoc () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: removeDocMutation,
          variables: {
            loan_doc: {
              id: this.document.id
            }
          }
        })
        this.setToast({
          active: true,
          event: 'success',
          message: `${this.document.name} successfully removed`,
          type: 'sticky'
        })
        this.$emit('success')
      } catch (error) {
        this.setToast({
          active: true,
          event: 'error',
          message: `Failed to delete ${this.document.name}`,
          description: 'Please try again or contact support if the issue persists.',
          type: 'sticky'
        })
      } finally {
        this.loading = false
        this.$emit('input', false)
      }
    }

  }
}

</script>
