const store = {
  namespaced: true,

  state: () => ({
    loanDocuments: null
  }),
  getters: {
    getLoanDocuments (state) {
      return state.loanDocuments
    }
  },
  mutations: {
    setLoanDocuments (state, loanDocs) {
      state.loanDocuments = loanDocs
    }
  }
}

export default store
