export const TASK_OBJECTS = {
  5: {
    title: 'Review loan application',
    icon: '$snDocumentImage',
    description: 'Borrower submitted the loan application that is ready for review'
  },
  6: {
    title: 'Review document',
    icon: '$snDocumentPdf',
    description: 'Document has been uploaded and ready for review'
  },
  8: {
    title: 'Review VOA',
    icon: '$snClipboard',
    description: 'Verification of Assets report has been ordered and ready for review'
  },
  21: {
    title: 'View appraisal',
    icon: '$snDocumentImage',
    description: 'Appraisal order has been completed and ready for review'
  },
  22: {
    title: 'Review credit report',
    icon: '$snCreditCard',
    description: 'Credit report has been ordered and ready for review'
  },
  23: {
    title: 'Run credit',
    icon: '$snDocumentCheck',
    description: 'Credit authorization has been provided and ready to run a credit check'
  },
  24: {
    title: 'Sign disclosures',
    icon: '$snDocumentSign',
    description: 'Disclosures documents are available for review and signatures'
  }
}

export const EVENT_IDS = {
  LOAN_APP: '5',
  DOCUMENT: '6',
  VOA: '8',
  APPRAISAL: '21',
  CREDIT_REPORT: '22',
  RUN_CREDIT: '23',
  SIGN_DISCLOSURES: '24'
}
