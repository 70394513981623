export default {
  NOT_ORDERED: 'NotOrdered',
  AUTHORIZATION_PENDING: 'AuthorizationPending',
  SOFT_ORDERED: 'SoftOrdered',
  HARD_ORDERED: 'HardOrdered',
  SOFT_RECEIVED: 'SoftReceived',
  HARD_RECEIVED: 'HardReceived',
  SOFT_NO_RESULTS_FOUND: 'SoftNoResultsFound',
  HARD_NO_RESULTS_FOUND: 'HardNoResultsFound',
  ERROR_AND_PDF_RECEIVED: 'ErrorAndPdfReceived',
  UNKNOWN_ERROR_RECEIVED: 'UnknownErrorReceived',
  SYSTEM_ERROR_RECEIVED: 'SystemErrorReceived',
  VIEW_HISTORY: 'ViewHistory',
  CAN_IMPORT_FROM_LOS: 'CanImportFromLos'
}
