import { extractGqlErrorMessage } from '../utilities'

export const handleError = (props) => {
  const {
    commit,
    error,
    getters,
    meta,
    provider,
    resource
  } = props

  const currentError = getters.getError(resource)
  let message = extractGqlErrorMessage(error)

  if (currentError?.constructor === Array) {
    message = currentError.concat({
      message,
      provider
    })
  }

  // set error in Vuex
  commit('addErrors', { resource, error: message })

  // call onError callback
  if (meta?.onError && typeof meta?.onError === 'function') {
    meta.onError({ message })
  }
}
