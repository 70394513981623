<template>
  <sn-side-panel
    :value="value"
    title="Review VOA"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="!!loadingVoa"
    @input="$emit('input', $event)"
    @primary-btn-click="handleBtnClick"
    @secondary-btn-click="handleBtnClick"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
      />
    </template>
    <loan-info-header :task="task" />
    <div class="panel ma-6 pa-6">
      <div
        v-if="!taskCompleted"
        class="sn-body-1 pb-8"
      >
        Verification of assets report is ready for review:
      </div>
      <div class="pb-6 d-flex align-center">
        <sn-illustration
          illustration="BankAccount"
          size="xs"
          class="mr-4"
        />
        <div>
          <div class="sn-h2">
            {{ totalAssets }}
          </div>
          <div class="sn-caption sn-text-primary-01">
            Total verified assets
          </div>
        </div>
      </div>
      <div class="pb-6 d-flex align-center">
        <sn-illustration
          illustration="MakeAnOffer"
          size="xs"
          class="mr-4"
        />
        <div>
          <div class="sn-h2">
            {{ numAccounts }}
          </div>
          <div class="sn-caption sn-text-primary-01">
            Total number of accounts
          </div>
        </div>
      </div>
      <sn-btn
        class="mt-6"
        size="standard"
        type="secondary"
        text="View report"
        :href="redirectHref"
      />
    </div>
  </sn-side-panel>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import { voaOrderQuery } from '~/components/loanOfficerApp/dashboard/gql/dashboardQueries.gql'
import { mapActions, mapGetters } from 'vuex'
import formatCurrency from '~/util/usCurrencyFilter'

export default {
  name: 'VoaSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader
  },
  filters: { formatCurrency },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loadingVoa: 0,
      loadingMutation: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    chipText () {
      return this.task?.resolvedAt ? `Completed on ${this.$moment(this.task?.resolvedAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolvedAt).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolvedAt
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    },
    accountReport () {
      return this.verification_order?.account_report
    },
    numAccounts () {
      return this.accountReport?.accounts?.length || '-'
    },
    totalAssets () {
      return formatCurrency(this.accountReport?.accounts?.reduce((acc, account) => {
        return acc + parseFloat(account?.available_balance)
      }, 0), false, false) || '$0.00'
    },
    redirectHref () {
      return `/servicer_loans/${this.$route.params.servicer_id}/loan_details/voa/${this.task?.task_object_guid}?loanAppGuid=${this.task?.userLoanApp?.guid}&page=ASSET_REPORT`
    }
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete']),
    async handleBtnClick () {
      this.loadingMutation = true
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: this.servicerId
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    }
  },
  apollo: {
    verification_order: {
      query: voaOrderQuery,
      variables () {
        return {
          voaOrderGuid: this.task?.task_object_guid
        }
      },
      loadingKey: 'loadingVoa',
      skip () {
        return !this.task?.task_object_guid
      },
      update (data) {
        return data?.verification_order
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';

.panel {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
}
</style>
