<template>
  <Chart
    :style="`overflow: auto; height: 134px; width: 164px;`"
    :options="chartOptions"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import More from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import SolidGauge from 'highcharts/modules/solid-gauge'

More(Highcharts)
SolidGauge(Highcharts)

export default {
  name: 'CreditReportGauge',
  components: {
    Chart
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    range: {
      type: Number,
      required: true
    }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'solidgauge',
          reflow: true,
          height: '100%'
        },
        title: null,
        // The background portion of the gauge
        pane: {
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '90%',
          startAngle: -120,
          endAngle: 120,
          background: {
            className: 'gauge-series',
            shape: 'arc',
            borderWidth: 0,
            backgroundColor: '#FFF0'
          }
        },
        tooltip: {
          enabled: false
        },
        yAxis: {
          min: 0,
          max: this.range,
          title: null,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          labels: {
            enabled: false
          },
          height: '100%'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          solidgauge: {
            linecap: 'square',
            rounded: false
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        series: [
          {
            type: 'solidgauge',
            rounded: true,
            animation: false,
            data: [{
              color: '#E5E5E5',
              radius: '100%%',
              innerRadius: '90%',
              y: this.range,
              label: this.value
            }],
            dataLabels: {
              align: 'center',
              borderWidth: 0,
              enabled: true,
              useHTML: true,
              y: -40,
              format: '<div class="gauge-wrapper"><span class="score"' + '">{point.label}</span><span class="sn-body-2">Qualifying</span><span class="sn-body-2">Score</span></div>'
            }
          },
          {
            data: [{
              y: this.value,
              color: this.$vuetify.theme?.themes?.light?.snBrand03 ?? '#0F5F5E'
            }],
            radius: '100%',
            innerRadius: '90%',
            linecap: 'rounded',
            rounded: true,
            useDial: false,
            type: 'solidgauge'
          }
        ]
      }
    }
  }
}
</script>
<style>
.score {
  font-size: 40px;
  font-family: Source Serif Pro;
  font-weight: 700;
  line-height: 30px;
}
.gauge-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align:center;
  color: #404040;
}
</style>
