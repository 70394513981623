<template>
  <div class="pa-0 ma-0 sn-body-1">
    {{ text }}
    <span
      class="sn-font-weight-bold"
      :class="`${ loan.status === 'Active' ? 'sn-text-success-04' : 'sn-text-primary-03' }`"
    >
      {{ loan.status }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'LoanSelectionLoanListItem',
  props: {
    loan: {
      type: Object,
      required: true
    }
  },
  computed: {
    text () {
      let text = this.loan.loanNumber
      if (this.loan.loanAmount) text += ' - ' + this.loan.loanAmount
      if (this.loan.address) text += ' - ' + this.loan.address
      if (this.loan.address2) {
        if (this.loan.address) {
          text += ', '
        } else {
          text += ' - '
        }
        text += this.loan.address2
      }
      if (this.loan.status) text += ' - '
      return text
    }
  }
}
</script>
