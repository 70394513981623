import getters from './DashboardGetters.js'
import actions from './DashboardActions.js'
import mutations from './DashboardMutations.js'

const store = {
  namespaced: true,

  state: () => ({
    user: {},
    servicerProfileId: '',
    servicer_profile: null,
    milestones: [],
    milestoneFilteredLoanTotals: {},
    loanAppTotal: 0,
    loanTotal: 0,
    error: false,
    loading: false,
    selectedLo: {},
    shareAppAnalytics: null,
    newLoanAppAnalytics: null,
    newProspectsAnalytics: null,
    abandonedLoanAppAnalytics: null,
    upcomingClosingsCount: 0,
    tasks: [],
    showSidePanel: false,
    currentSidePanel: null,
    currentTask: null,
    recentActivityLogs: [],
    activityFeedSettings: [],
    isAssignedLO: false
  }),
  getters,
  actions,
  mutations
}

export default store
