/**
 * this was built to be used as vue filter
 * example use case as filter in a vue template including decimals: {{ 1234546.12 | formatCurrency() }}
 *
 * example use case as filter in a vue template excluding decimals: {{ 1234546.12 | formatCurrency(/* ignoreDecimal*\/ true)}}
 */
// regex adds commas every 3 digit
export default (num, ignoreDecimal = false, forceFormatting = false) => {
  if (num === null) return null

  let numAsString = String(num)

  if (numAsString === '.') {
    numAsString = '0.'
  }

  const decimalCount = getDecimals(numAsString)
  const numAsNumber = Number(numAsString)
  let numAsCurrency = numAsNumber.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

  if (!forceFormatting && (ignoreDecimal || decimalCount === -1)) {
    numAsCurrency = numAsCurrency.slice(0, -3)
  } else if (!forceFormatting && decimalCount < 3) {
    numAsCurrency = numAsCurrency.slice(0, -3 + decimalCount)
  }

  if (num < 0) {
    return `- ${numAsCurrency.slice(1, numAsCurrency.length)}`
  }

  return numAsCurrency
}

function getDecimals (strNum) {
  const firstDecimalIndex = strNum.indexOf('.')
  const noDecimal = firstDecimalIndex === -1

  if (noDecimal) return -1

  return strNum.substring(firstDecimalIndex, strNum.length).length
}
