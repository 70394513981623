<template>
  <sn-side-panel
    :value="value"
    title="Run credit"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="!!loadingCreditInfo"
    @input="$emit('input', $event)"
    @primary-btn-click="handleBtnClick"
    @secondary-btn-click="handleBtnClick"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
      />
    </template>
    <loan-info-header :task="task" />
    <div class="panel ma-6 px-6 pb-6">
      <div class="d-flex align-center pb-6">
        <div class="sn-body-2 pr-6">
          {{ authText }}
        </div>
        <sn-illustration
          illustration="CreditCard"
          size="md"
        />
      </div>
      <div>
        <div
          v-for="(borrower, index) in borrowerDetails"
          :key="index"
          class="pb-6"
        >
          <div class="sn-subtitle-2 pb-4">
            {{ !borrower.co_borrower ? 'Borrower' : 'Co-borrower' }} details
          </div>
          <div class="two-column-grid">
            <div
              v-for="(detail, index) in borrower.details"
              :key="detail.value + index"
              class="sn-caption sn-text-primary-01 pb-1"
            >
              <div>
                {{ detail.title }}
              </div>
              <div class="sn-body-1">
                {{ detail.value }}
              </div>
            </div>
          </div>
        </div>
        <sn-btn
          size="standard"
          type="secondary"
          text="Run credit check"
          :href="loanDetailsHref"
        />
      </div>
    </div>
  </sn-side-panel>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import formatCurrency from '~/util/usCurrencyFilter'
import { loanDocQuery, loanAppBorrowerQuery } from '~/components/loanOfficerApp/dashboard/gql/dashboardQueries.gql'

export default {
  name: 'RunCreditSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader
  },
  filters: { formatCurrency },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loadingCreditInfo: 0,
      loadingMutation: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    chipText () {
      return this.task?.resolved_at ? `Completed on ${this.$moment(this.task?.resolved_at).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolved_at).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolved_at
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    },
    authText () {
      let suffix = ''
      if (this.loan_borrowers?.length === 1) {
        suffix = ` on ${this.$moment(this.loan_doc?.created_at).format('MMMM DD, YYYY')} at ${this.$moment(this.loan_doc?.created_at).format('h:mm A')}`
      }
      return `Credit authorization has been granted${suffix}`
    },
    borrowerDetails () {
      return this.loan_borrowers?.map(borrower => {
        return {
          ...borrower,
          details: [
            {
              title: 'First name',
              value: borrower.first_name || '-'
            },
            {
              title: 'Last name',
              value: borrower.last_name || '-'
            },
            {
              title: 'SSN',
              value: this.formatSSN(borrower.ssn) || '-'
            },
            {
              title: 'Date of birth',
              value: borrower.dob ? this.$moment(borrower.dob).format('MMM DD, YYYY') : '-'
            }
          ]
        }
      })
    },
    loanDetailsHref () {
      let query = ''
      if (this.task?.loan?.guid) {
        query = `?loanGuid=${this.task.loan.guid}`
      } else if (this.task?.userLoanApp?.guid) {
        query = `?loanAppGuid=${this.task.userLoanApp.guid}`
      }
      return `/servicer_loans/${this.servicerId}/loan_details/services${query}`
    }
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete']),
    async handleBtnClick () {
      this.loadingMutation = true
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: this.servicerId
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    },
    formatSSN (ssn) {
      if (!ssn) return ''
      return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`
    }
  },
  apollo: {
    loan_doc: {
      query: loanDocQuery,
      variables () {
        return {
          loanDocGuid: this.task?.task_object_guid
        }
      },
      skip () {
        return !this.task?.task_object_guid
      },
      update (data) {
        return data?.loan_doc
      }
    },
    loan_borrowers: {
      query: loanAppBorrowerQuery,
      loadingKey: 'loadingCreditInfo',
      fetchPolicy: 'network-only',
      skip () {
        return !this.task?.userLoanApp?.guid && !this.task?.loan?.guid
      },
      variables () {
        return {
          loanAppGuid: this.task?.userLoanApp?.guid || '',
          loanGuid: this.task?.loan?.guid || '',
          queryForLoanApp: !!this.task?.userLoanApp?.guid
        }
      },
      update (data) {
        if (!data?.loan && !data?.user_loan_app) return []
        if (data?.loan) {
          const cfrs = data.loan.custom_form_requests?.filter(cfr => cfr.form_type === 'credit_auth_request' && cfr.accepted)
          return data.loan.loan_borrowers.filter(lb => {
            return cfrs.some(cfr => cfr.loan_borrower_id === lb.id)
          })
        } else {
          const loanApp = data.user_loan_app
          const borrowers = []
          if (loanApp.borrower?.credit_auth) {
            borrowers.push(loanApp.borrower)
          }
          if (loanApp.co_borrower?.credit_auth) {
            borrowers.push({
              ...loanApp.co_borrower,
              co_borrower: true
            })
          }
          return borrowers
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';
.panel {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
}

.two-column-grid {
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
</style>
