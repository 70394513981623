<template>
  <sn-side-panel
    :value="value"
    title="Review appraisal"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="!!loadingAppraisal"
    @input="$emit('input', $event)"
    @primary-btn-click="handleBtnClick"
    @secondary-btn-click="handleBtnClick"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
      />
    </template>
    <loan-info-header :task="task" />
    <div
      v-if="!taskCompleted"
      class="sn-body-1 mx-6 mt-6"
    >
      Appraisal is ready for review:
    </div>
    <div class="doc-border ma-6 pa-4 d-flex justify-space-between align-center">
      <div
        class="d-flex align-center"
      >
        <div class="circle">
          <v-icon
            size="25px"
            color="snPrimary"
          >
            $snDollarHouse
          </v-icon>
        </div>
        <div class="px-6">
          <div class="sn-body-1 pb-2">
            Appraisal
          </div>
          <div class="sn-body-2 sn-text-primary-01">
            {{ appraisalSubtitle }}
          </div>
        </div>
      </div>
      <sn-btn
        text="View"
        type="text"
        size="standard"
        @click="showAppraisalModal = true"
      />
    </div>
    <sn-pdf-viewer
      v-if="appraisalHistories"
      :dialog="showAppraisalModal"
      :pdfs="[lastAppraisalPdf]"
      :title="`Appraisal for ${actorName}`"
      @close-dialog="showAppraisalModal = false"
      @input="showAppraisalModal = false"
    />
  </sn-side-panel>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import { appraisalTaskQuery } from '~/components/loanOfficerApp/dashboard/gql/dashboardQueries.gql'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppraisalSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loadingAppraisal: 0,
      loadingMutation: false,
      appraisal: null,
      showAppraisalModal: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    chipText () {
      return this.task?.resolvedAt ? `Completed on ${this.$moment(this.task?.resolvedAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolvedAt).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolvedAt
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    },
    appraisalHistories () {
      return this.appraisal?.appraisalHistories
    },
    lastAppraisalPdf () {
      return this.appraisalHistories?.filter(h => h.imageUrl)[0]
    },
    actorName () {
      return this.task?.actorName
    },
    appraisalSubtitle () {
      return `Uploaded on ${this.$moment(this.appraisalHistories ? this.lastAppraisalPdf?.updatedAt : '').format('MMMM DD, YYYY')}`
    }
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete']),
    async handleBtnClick () {
      this.loadingMutation = true
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: this.servicerId
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    }
  },
  apollo: {
    appraisal: {
      query: appraisalTaskQuery,
      variables () {
        return {
          appraisalGuid: this.task?.task_object_guid || ''
        }
      },
      loadingKey: 'loadingAppraisal',
      skip () {
        return !this.task?.task_object_guid
      },
      update (data) {
        const histories = data.appraisal?.appraisalHistories?.nodes
        return {
          ...data.appraisal,
          appraisalHistories: histories
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';
.doc-border {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 4px;
  background-color: $sn-gray-01;
}

</style>
