import axios from '~/util/axios'

export default {
  index (groupKey, queryType = null, queryId = null) {
    return axios.get('/api/v1/custom_settings', {
      params: {
        group_key: groupKey,
        queryType: queryType,
        queryId: queryId
      }
    })
  }
}
