import getters from './pricingGetters.js'
import actions from './pricingActions.js'
import mutations from './pricingMutations.js'

const store = {
  namespaced: true,
  state: () => ({
    form: {},
    channelIndex: '',
    searchId: '',
    quote: {},
    productDetails: {},
    productDetailsError: false,
    productDetailsLoading: false,
    pricingProductsIsLoading: false,
    ineligiblePricingProductsIsLoading: false,
    searchCriteriaIsLoading: false,
    searchCriteriaGuid: ''
  }),
  getters,
  actions,
  mutations
}

export default store
