<template>
  <sn-modal
    :value="value"
    title="Rename document?"
    type="decision"
    primary-text="Rename"
    :loading="loadingAction"
    :disabled="renameError"
    keydown-submit
    @submit="renameFile"
    @input="handleRenameClose"
  >
    <sn-text-field
      v-model="docName"
      label="Document name"
      :error="renameError"
      :error-messages="warningText"
    />
  </sn-modal>
</template>

<script>
import { updateDocMutation } from '~/components/loanOfficerApp/Queries/loanDocsQueries.gql'
import { mapMutations } from 'vuex'

export default {
  name: 'RenameLoanDocModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loanDoc: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      docName: this.loanDoc?.name || '',
      warningText: '',
      renameError: false,
      loadingAction: false
    }
  },
  watch: {
    docName (val) {
      this.renameCheck(val)
    },
    loanDoc (val) {
      this.docName = val?.name
    }
  },
  methods: {
    ...mapMutations([
      'setToast'
    ]),
    handleRenameClose () {
      this.warningText = ''
      this.renameError = false
      this.docName = this.loanDoc.name
      this.$emit('input', false)
    },
    renameCheck (name) {
      if (!name || name.trim() === '') {
        this.warningText = 'Document name is required'
        this.renameError = true
      } else if (this.hasSpecialCharacters(name)) {
        this.warningText = 'Special characters not supported: !@#$%^&*()?><|'
        this.renameError = true
      } else {
        this.warningText = ''
        this.renameError = false
      }
    },
    hasSpecialCharacters (str) {
      return !/^[\w- ]+$/.test(str)
    },
    async renameFile () {
      if (!this.loanDoc) return
      this.loadingAction = true
      if (this.warningText !== '') {
        this.loadingAction = false
        return
      }
      try {
        await this.$apollo.mutate({
          mutation: updateDocMutation,
          variables: {
            loan_doc: {
              id: this.loanDoc.id,
              name: this.docName
            }
          }
        })
        this.setToast({
          active: true,
          event: 'success',
          message: `Successfully renamed ${this.loanDoc.name} to ${this.docName}`
        })
        this.$emit('success', this.docName)
      } catch (error) {
        this.setToast({
          active: true,
          event: 'error',
          message: `Failed to rename ${this.docName}`,
          description: 'Please try again or contact support'
        })
      } finally {
        this.loadingAction = false
        this.$emit('input', false)
      }
    }
  }
}
</script>
