import { TASK_OBJECTS } from '../tasks/tasksHelpers'

export default {
  getMilestones: state => {
    return state.milestones
  },
  getMilestoneFilteredLoanTotals: state => {
    return state.milestoneFilteredLoanTotals
  },
  getLoanAppTotal: state => {
    return state.loanAppTotal
  },
  getLoanTotal: state => {
    return state.loanTotal
  },
  getUpcomingClosingsCount: state => {
    return state.upcomingClosingsCount
  },
  getMilestoneFilterId: (state) => (filter) => {
    const milestone = state.milestones.find(milestone => milestone.name === filter)
    return milestone ? milestone.id : 0
  },
  getServicerProfileId: state => {
    return state.servicerProfileId
  },
  getServicerProfile: state => {
    return state.servicerProfile
  },
  getError: state => {
    return state.error
  },
  getLoading: state => {
    return state.loading
  },
  getLoanAppsPrev30Days: state => {
    return state.newLoanAppAnalytics?.analytic || 0
  },
  getNewLoanAppChangePercentage: state => {
    return state.newLoanAppAnalytics?.percent_change
  },
  getSharesPrev30Days: state => {
    return state.shareAppAnalytics?.analytic || 0
  },
  getNewProspectsAnalytics: state => {
    return state.newProspectsAnalytics?.analytic || 0
  },
  getNewProspectsPercentage: state => {
    return state.newProspectsAnalytics?.percent_change
  },
  getShareChangePercentage: state => {
    return state.shareAppAnalytics?.percent_change
  },
  getAbandonedLoanAppsPrev30Days: state => {
    return state.abandonedLoanAppAnalytics?.analytic || 0
  },
  getTasks: state => {
    return state.tasks
  },
  getShowSidePanel: state => {
    return state.showSidePanel
  },
  getCurrentSidePanel: state => {
    return state.currentSidePanel
  },
  getCurrentTask: state => {
    return state.currentTask
  },
  getErrorToastContent: state => {
    return {
      active: true,
      message: 'Sorry, there was a problem marking this task complete',
      description: 'please try again or contact support',
      type: 'simple',
      event: 'error'
    }
  },
  getRecentActivityLogs: state => {
    return state.recentActivityLogs
  },
  getTaskName: (state) => (task) => {
    return TASK_OBJECTS[task.eventId].title
  },
  getActivityFeedSettings: state => {
    return state.activityFeedSettings
  },
  getIsAssignedLO (state) {
    return state.isAssignedLO
  },
  getSelectedLo: state => {
    return state.selectedLo
  }
}
