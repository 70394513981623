export default {
  openModal (context, { modalName, guid, ownerGuid, loadTask }) {
    if (loadTask) {
      context.commit('addLoadedTask', guid)
    }
    context.commit('setCurrentTaskListOwnerGuid', ownerGuid)
    context.commit('setCurrentTaskGuid', guid)
    context.commit('setCurrentModal', modalName)
  },
  closeModal (context) {
    context.commit('setCurrentModal', '')
  },
  openPdfViewer (context, { guid, ownerGuid }) {
    context.commit('addLoadedTask', guid)
    context.commit('setCurrentTaskListOwnerGuid', ownerGuid)
    context.commit('setCurrentTaskGuid', guid)
    context.commit('setCurrentPdfViewer', guid)
  },
  closePdfViewer (context) {
    context.commit('setCurrentPdfViewer', '')
  },
  openSidePanel (context, { guid, ownerGuid }) {
    context.commit('addLoadedTask', guid)
    context.commit('setCurrentTaskListOwnerGuid', ownerGuid)
    context.commit('setCurrentTaskGuid', guid)
    context.commit('setCurrentSidePanel', guid)
  },
  closeSidePanel (context) {
    context.commit('setCurrentSidePanel', '')
  }
}
