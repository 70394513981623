<template>
  <sn-side-panel
    :value="value"
    title="Review loan application"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="!!loadingLoanApp"
    @input="$emit('input', $event)"
    @primary-btn-click="handleBtnClick"
    @secondary-btn-click="handleBtnClick"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
      />
    </template>
    <loan-info-header :task="task" />
    <loan-app-panel-body
      v-if="!loadingLoanApp"
      :loan-app-actions-mixin_loan-app="loanApp"
      :task-event-id="task.eventId"
      @pdf-viewed="handlePdfViewed"
    />
  </sn-side-panel>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import LoanAppPanelBody from '~/components/loanOfficerApp/dashboard/tasks/loanApp/LoanAppPanelBody.vue'
import { loanAppTaskQuery } from '~/components/loanOfficerApp/Queries/loanAppQueries.gql'
import isEmpty from 'lodash/isEmpty'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoanAppSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader,
    LoanAppPanelBody
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loadingLoanApp: 0,
      loadingMutation: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    chipText () {
      return this.task?.resolvedAt ? `Completed on ${this.$moment(this.task?.resolvedAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolvedAt).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolvedAt
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    }
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete', 'displayToast']),
    async handleBtnClick () {
      this.loadingMutation = true
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: this.servicerId
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    },
    handlePdfViewed () {
      if (!this.task?.resolvedAt) {
        this.displayToast({
          success: true,
          eventType: 'Completed'
        })
        this.$emit('reload-tasks')
      }
    }
  },
  apollo: {
    loanApp: {
      query: loanAppTaskQuery,
      variables () {
        return {
          loanAppGuid: this.task?.userLoanApp?.guid || ''
        }
      },
      loadingKey: 'loadingLoanApp',
      skip () {
        return !this.task?.userLoanApp?.guid
      },
      update (data) {
        const loanApp = data.user_loan_app || {}
        if (!isEmpty(loanApp)) {
          loanApp.imageUrl = `/servicer_leads/export_loan_app/${this.servicerId}?app_user_id=${loanApp?.appUser?.id}&format=.pdf&disposition=inline&loan_app_guid=${loanApp.guid}`
        }
        if (loanApp?.relevant_values) {
          // Add spaces and new lines so the JSON string is pretty-printed
          loanApp.relevant_values = JSON.stringify(JSON.parse(loanApp.relevant_values), null, 2)
        }
        if (loanApp?.loan_app_json) {
          // Add spaces and new lines so the JSON string is pretty-printed
          loanApp.loan_app_json = JSON.stringify(JSON.parse(loanApp.loan_app_json), null, 2)
        }

        return loanApp
      }
    }
  }
}
</script>
