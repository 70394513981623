import apolloClient from '~/util/apolloClient'
import { create as createRecord } from './actions/index'
import { handleError } from './actions/handle_error'

const { provider: { defaultClient: $apollo } } = apolloClient()

export default {
  createRecord: (context, payload) => createRecord({ context, dataProvider: $apollo, payload }),
  getRecord ({ commit, getters }, { resource, query, key, value, responseField, ...rest }) {
    if (getters.getRecordList(resource).some(record => record[key] === value)) return

    commit('setIsPageLoading', { resource, isLoading: true })

    $apollo.query({
      query,
      variables: rest
    }).then(({ data }) => {
      commit('addRecord', { resource, record: data[responseField] })
    }).catch(
      error => handleError({ commit, error, getters, resource, ...rest })
    ).finally(() => {
      commit('setIsPageLoading', { resource, isLoading: false })
    })
  },
  getList ({ commit, getters }, { query, resource, responseField, ...rest }) {
    commit('setIsPageLoading', { resource, isLoading: true })
    return $apollo.query({
      fetchPolicy: 'network-only',
      query,
      variables: rest
    }).then(({ data }) => {
      commit('setData', { resource, data: data[responseField] })
    }).catch(
      error => handleError({ commit, error, getters, resource, ...rest })
    ).finally(() => {
      commit('setIsPageLoading', { resource, isLoading: false })
    })
  },
  getPagedList ({ commit, getters }, { query, resource, responseField, ...rest }) {
    commit('setIsPageLoading', { resource, isLoading: true })
    return $apollo.query({
      fetchPolicy: 'network-only',
      query,
      variables: rest
    }).then(({ data }) => {
      commit('setPagedData', {
        resource,
        data: data[responseField].nodes,
        filteredTotal: data[responseField].filteredTotal,
        pageInfo: data[responseField].pageInfo
      })
    }).catch(
      error => handleError({ commit, error, getters, resource, ...rest })
    ).finally(() => {
      commit('setIsPageLoading', { resource, isLoading: false })
    })
  },
  getLatest ({ commit, getters }, { query, resource, responseField, ...rest }) {
    commit('setIsPageLoading', { resource, isLoading: true })
    $apollo.query({
      fetchPolicy: 'network-only',
      query,
      variables: rest
    }).then(({ data }) => {
      if (data[responseField]) {
        const recordList = getters.getRecordList(resource)
        const index = recordList.findIndex((record) => record.guid === data[responseField].guid)
        if (index >= 0) {
          commit('updateRecord', { resource, newRecord: data[responseField], index: index })
        } else {
          commit('addRecord', { resource, record: data[responseField] })
        }
      }
    }).catch(
      error => handleError({ commit, error, getters, resource, ...rest })
    ).finally(() => {
      commit('setIsPageLoading', { resource, isLoading: false })
    })
  },
  getPermissions ({ commit }, { query, setting, ...rest }) {
    commit('setIsPageLoading', { resource: 'permissions', isLoading: true })
    $apollo.query({
      query,
      variables: rest
    }).then(({ data }) => {
      var permissions = rest.queryForLoan
        ? data.loan?.servicer_profile[setting]
        : data.loanApp?.servicer_profile[setting]
      commit('setPermissions', { permissions: permissions })
    }).finally(() => {
      commit('setIsPageLoading', { resource: 'permissions', isLoading: false })
    })
  },
  getState ({ commit }, { query, resource, responseField, stateEnum, ...variables }) {
    commit('setIsPageLoading', { resource, isLoading: true })
    return $apollo.query({
      fetchPolicy: 'network-only',
      query,
      variables: {
        ...variables
      }
    }).then(({ data }) => {
      const state = stateEnum[data[responseField].state]
      commit('setState', { resource, status: state })
      commit('setError', { resource, error: data[responseField]?.error })
    }).finally(() => {
      commit('setIsPageLoading', { resource, isLoading: false })
    })
  },
  setState (context, { resource, stateEnum }) {
    context.commit('setState', { resource, status: stateEnum })
  },
  updateRecord ({ commit, getters }, { resource, newRecord }) {
    const recordList = getters.getRecordList(resource)
    const index = recordList.findIndex((record) => record.guid === newRecord.guid)
    if (index >= 0) commit('updateRecord', { resource, newRecord, index })
  },
  getItem ({ commit }, { resource, query, responseField, ...variables }) {
    $apollo.query({
      query,
      variables: {
        ...variables
      }
    }).then(({ data }) => {
      commit('setItem', { resource, item: data[responseField] })
    }).catch(error => {
      commit('setError', { resource, error })
    })
  }
}
