<template>
  <div class="d-flex align-center pt-4 mr-8">
    <div class="mr-4">
      <sn-chip
        :color="chipColor"
        status-chip
      >
        {{ chipText }}
      </sn-chip>
    </div>
    <div
      v-if="!completed"
      class="sn-caption-01"
    >
      {{ subText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidePanelSubtitle',
  props: {
    chipText: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    completed: {
      type: Boolean,
      default: false
    },
    urgent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chipColor () {
      if (this.completed) return 'snSuccess01'
      return this.urgent ? 'snCaution01' : 'snGray01'
    }
  }
}
</script>
